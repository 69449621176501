import BigNumber from 'bignumber.js';
import { Address, erc20ABI, useContractReads } from 'wagmi';

import {
  formatCompactNumber,
  formatDecimalPlaces,
  fromBigNumberAmount,
  fromBigNumberPrice,
} from '@/lib/format';

import MlpManagerABI from '@/abis/MlpManager.json';
export const useDashboard = () => {
  const { data }: { data: any } = useContractReads({
    watch: true,
    contracts: [
      {
        address: process.env.NEXT_PUBLIC_MLP as Address,
        abi: erc20ABI,
        functionName: 'totalSupply',
      },
      {
        address: process.env.NEXT_PUBLIC_MLP_MANAGER_ADDRESS as Address,
        // @ts-ignore
        abi: MlpManagerABI,
        functionName: 'getPrice',
        args: [true],
      },
    ],
  });
  if (data) {
    const _price = formatDecimalPlaces(fromBigNumberPrice(data?.[1].result));
    const _supply = formatDecimalPlaces(fromBigNumberAmount(data?.[0].result, 'MLP'));
    const _marketCap = formatDecimalPlaces(
      fromBigNumberPrice(
        fromBigNumberAmount(
          new BigNumber(data?.[1].result || 0).times(data?.[0].result || 0),
          'MLP',
        ),
      ),
    );
    // console.log(fromBigNumberAmount(data?.[0].result).toString());
    return {
      price: Number(_price) > 0 ? _price : '1',
      supply: _supply,
      marketCap: formatCompactNumber(_marketCap),
      formatSupply: formatCompactNumber(_supply),
    };
  }

  return {
    price: '0',
    supply: '0',
    marketCap: '0',
    formatSupply: '0',
  };
};
