import { Address, useAccount, useContractReads } from 'wagmi';

import OrderBookABI from '@/abis/OrderBook.json';

export const useTypeOrdersIndex = () => {
  const { address } = useAccount();

  const { data, isLoading, isSuccess } = useContractReads({
    enabled: !!address,
    watch: true,
    contracts: [
      {
        address: process.env.NEXT_PUBLIC_ORDER_BOOK_ADDRESS as Address,
        // @ts-ignore
        abi: OrderBookABI,
        functionName: 'swapOrdersIndex',
        args: [address as Address],
      },
      {
        address: process.env.NEXT_PUBLIC_ORDER_BOOK_ADDRESS as Address,
        // @ts-ignore
        abi: OrderBookABI,
        functionName: 'increaseOrdersIndex',
        args: [address as Address],
      },
      {
        address: process.env.NEXT_PUBLIC_ORDER_BOOK_ADDRESS as Address,
        // @ts-ignore
        abi: OrderBookABI,
        functionName: 'decreaseOrdersIndex',
        args: [address as Address],
      },
    ],
  });

  return {
    data,
    isLoading,
    isSuccess,
  };
};
