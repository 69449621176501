import { Address, useAccount, useContractRead, useContractReads } from 'wagmi';

import { fromBigNumberAmount } from '@/lib/format';
import { getPositions } from '@/lib/legacy';
import { useGetPositionList } from '@/hooks/contract';

import { useAmountStore, useInfoTokensStore } from '@/stores';

import VaultABI from '@/abis/Vault.json';
import { ALL_TOKENS } from '@/consts';

import { IVaultAmounts, TApproveKey } from '@/types';

export const useTotalTokenWeights = () => {
  const { data, isSuccess } = useContractRead({
    address: process.env.NEXT_PUBLIC_VAULT_ADDRESS as Address,
    abi: VaultABI,
    functionName: 'totalTokenWeights',
    watch: true,
  });

  return {
    data: isSuccess ? data : 0,
    isSuccess,
  };
};

export const useInitVaultDataAmount = () => {
  const { setPoolAmounts, setReservedAmounts } = useAmountStore();

  const poolAmountsContracts = Object.keys(ALL_TOKENS).map((item) => ({
    address: process.env.NEXT_PUBLIC_VAULT_ADDRESS as Address,
    abi: VaultABI,
    functionName: 'poolAmounts',
    args: [item],
  }));

  const reservedAmountsContracts = Object.keys(ALL_TOKENS).map((item) => ({
    address: process.env.NEXT_PUBLIC_VAULT_ADDRESS as Address,
    abi: VaultABI,
    functionName: 'reservedAmounts',
    args: [item],
  }));

  const contracts = [...poolAmountsContracts, ...reservedAmountsContracts];

  useContractReads({
    // @ts-ignore
    contracts: contracts,
    onSuccess(data) {
      const poolAmounts = {} as IVaultAmounts;
      const reservedAmounts = {} as IVaultAmounts;
      const len = Object.keys(ALL_TOKENS).length;
      for (let i = 0; i < len; i++) {
        const result = data[i]?.result as number;
        const address = Object.keys(ALL_TOKENS)[i] as Address;
        poolAmounts[address] = result
          ? fromBigNumberAmount(result.toString(), ALL_TOKENS[address] as TApproveKey).toNumber()
          : 0;
      }
      for (let j = len; j < len * 2; j++) {
        const result = data[j]?.result as number;
        const address = Object.keys(ALL_TOKENS)[j - len] as Address;
        reservedAmounts[address] = result
          ? fromBigNumberAmount(result.toString(), ALL_TOKENS[address] as TApproveKey).toNumber()
          : 0;
      }
      setPoolAmounts(poolAmounts);
      setReservedAmounts(reservedAmounts);
    },
  });
};

export const useGetPositionDelta = () => {
  const { address } = useAccount();
  const { data: positionData, tokenMap: feeMap } = useGetPositionList();
  const { infoTokens } = useInfoTokensStore();
  const { data: liquidateFee } = useGetLiquidationFeeUsd();
  const { positions } = getPositions(positionData, feeMap, infoTokens, address, liquidateFee);

  const contractArr = positions.map((item) => {
    return {
      address: process.env.NEXT_PUBLIC_VAULT_ADDRESS as Address,
      abi: VaultABI,
      functionName: 'getPositionDelta',
      args: [
        address,
        item.collateralToken.address,
        item.indexToken.address,
        item.isLong,
        item.isTokenBased,
      ],
    };
  });

  const { data, isSuccess } = useContractReads({
    enabled: !!address,
    watch: true,
    // @ts-ignore
    contracts: contractArr,
  });

  const tokenMap: any = {};
  if (isSuccess) {
    positions.forEach((item, index) => {
      const key = [item.collateralToken.address, item.indexToken.address, item.isLong].join('@');
      tokenMap[key] = data![index].result;
    });
  }
  return {
    tokenMap,
    isSuccess,
  };
};

export const useGetLiquidationFeeUsd = () => {
  const { data, isSuccess } = useContractRead({
    address: process.env.NEXT_PUBLIC_VAULT_ADDRESS as Address,
    abi: VaultABI,
    functionName: 'liquidationFeeUsd',
    watch: true,
  });

  return {
    data: isSuccess ? data : 0,
    isSuccess,
  };
};
