import { Address, useContractReads } from 'wagmi';

import { useFeeStore } from '@/stores';

import OrderBookABI from '@/abis/OrderBook.json';
import PositionRouterABI from '@/abis/PositionRouter.json';

export const useInitWriteContractFee = () => {
  const { setLimitOrderFee, setMarketOrderFee } = useFeeStore();

  useContractReads({
    watch: true,
    contracts: [
      // limitOrder fee
      {
        address: process.env.NEXT_PUBLIC_ORDER_BOOK_ADDRESS as Address,
        // @ts-ignore
        abi: OrderBookABI,
        functionName: 'minExecutionFee',
      },
      // marketOrder fee
      {
        address: process.env.NEXT_PUBLIC_POSITION_ROUTER_ADDRESS as Address,
        // @ts-ignore
        abi: PositionRouterABI,
        functionName: 'minExecutionFee',
      },
    ],
    onSuccess(data) {
      setLimitOrderFee((data?.[0].result as number) ?? 500000);
      setMarketOrderFee((data?.[1].result as number) ?? 4000);
    },
  });
};
