import BigNumber from 'bignumber.js';
import { Address, useAccount, useContractRead, useContractReads } from 'wagmi';

import { getPositionKey } from '@/lib/legacy';

import ReaderABI from '@/abis/Reader.json';
import VaultABI from '@/abis/Vault.json';
import VaultUtilsABI from '@/abis/VaultUtils.json';
import { collateralTokensArr, indexTokensArr, isLongArr, isTokenBasedArr } from '@/consts';

export const useGetPositionList = () => {
  const { address } = useAccount();
  const { data, isLoading, isSuccess }: { data: any; isLoading: boolean; isSuccess: boolean } =
    useContractRead({
      address: process.env.NEXT_PUBLIC_READER_ADDRESS as Address,
      abi: ReaderABI,
      enabled: !!address,
      watch: true,
      functionName: 'getPositions',
      args: [
        process.env.NEXT_PUBLIC_VAULT_ADDRESS,
        address,
        collateralTokensArr,
        indexTokensArr,
        isLongArr,
        isTokenBasedArr,
      ],
    });

  const tokenMap: any = {};
  const contractMarginRatioArr: any = [];
  // const contractLiquidationFeeUSDArr: any = [];
  const contractFundingFeeArr: any = [];
  const contractPositionFeeArr: any = [];
  const keyList: any = [];

  if (data) {
    const propsLength = 9;
    for (let i = 0; i < collateralTokensArr.length; i++) {
      if (BigNumber(data[i * propsLength]).eq(0)) {
        continue;
      }
      const key = getPositionKey(
        address as Address,
        collateralTokensArr[i]!,
        indexTokensArr[i]!,
        isLongArr[i]!,
        isTokenBasedArr[i]!,
      );
      keyList.push(key);
      contractMarginRatioArr.push({
        address: process.env.NEXT_PUBLIC_VAULT_UTILS_ADDRESS,
        abi: VaultUtilsABI,
        functionName: 'getMarginRatio',
        args: [data[i * propsLength]],
      });
      // contractLiquidationFeeUSDArr.push({
      //   address: process.env.NEXT_PUBLIC_VAULT_UTILS_ADDRESS,
      //   abi: VaultUtilsABI,
      //   functionName: 'getLiquidationFeeUSD',
      //   args: [data[i * propsLength]],
      // });
      contractFundingFeeArr.push({
        address: process.env.NEXT_PUBLIC_VAULT_ADDRESS,
        abi: VaultABI,
        functionName: 'getFundingFee',
        args: [
          address,
          collateralTokensArr[i],
          indexTokensArr[i],
          isLongArr[i],
          isTokenBasedArr[i],
          data[i * propsLength],
          data[i * propsLength + 3],
        ],
      });
      contractPositionFeeArr.push({
        address: process.env.NEXT_PUBLIC_VAULT_ADDRESS,
        abi: VaultABI,
        functionName: 'getPositionFee',
        args: [
          address,
          collateralTokensArr[i],
          indexTokensArr[i],
          isLongArr[i],
          isTokenBasedArr[i],
          data[i * propsLength],
        ],
      });
    }
  }

  const { data: marginRatios } = useContractReads({
    enabled: !!address,
    contracts: contractMarginRatioArr,
  });
  // const { data: liquidationFeeUSDs } = useContractReads({
  //   enabled: !!address,
  //   contracts: contractLiquidationFeeUSDArr,
  // });
  const { data: fundingFeeRes } = useContractReads({
    enabled: !!address,
    contracts: contractFundingFeeArr,
  });
  const { data: positionFeeRes } = useContractReads({
    enabled: !!address,
    contracts: contractPositionFeeArr,
  });

  for (let i = 0; i < keyList.length; i++) {
    tokenMap[keyList[i]] = {
      marginRatio: marginRatios?.[i].result,
      // liquidationFeeUSD: liquidationFeeUSDs?.[i].result,
      fundingFee: fundingFeeRes?.[i].result,
      positionFee: positionFeeRes?.[i].result,
    };
  }

  return {
    data,
    tokenMap,
    isLoading,
    isSuccess,
  };
};
