import BigNumber from 'bignumber.js';
import { Address, useContractRead } from 'wagmi';

import {
  formatDecimalPlaces,
  formatNumber,
  fromBigNumberAmount,
  fromBigNumberPrice,
  parseAmount,
} from '@/lib/format';

import { useInfoTokensStore, usePricesStore } from '@/stores';

import VaultReaderABI from '@/abis/VaultReader.json';
import { POOL_TOKENS, TOKENS_COLOR } from '@/consts/tokens';

import { ChartsData, InfoToken, TApproveKey, TradeTokenKey, TTradeToken } from '@/types';
import { IPrices } from '@/types/api';

export const useTokenInfos = (vaultPropsLength = 15) => {
  const { setInfoTokens } = useInfoTokensStore();
  const { setPrices } = usePricesStore();

  const { data }: { data?: number[] } = useContractRead({
    address: process.env.NEXT_PUBLIC_VAULT_READER_ADDRESS as Address,
    abi: VaultReaderABI,
    functionName: 'getVaultTokenInfoV4',
    watch: true,
    args: [
      process.env.NEXT_PUBLIC_VAULT_ADDRESS,
      process.env.NEXT_PUBLIC_POSITION_MANAGER_ADDRESS,
      process.env.NEXT_PUBLIC_ETH,
      parseAmount('1', 'ETH'),
      Object.keys(POOL_TOKENS),
    ],
    onSuccess(data) {
      const infoTokens = getInfoTokens(POOL_TOKENS, data, vaultPropsLength);
      const result = {} as IPrices;
      for (const token of infoTokens) {
        result[token.address] = token.maxPrice
          ? Number.parseFloat(token.maxPrice.toString()).toFixed(2)
          : '0.00';
      }
      setPrices(result);
      setInfoTokens(infoTokens);
    },
  });
  const _result = getPool(getInfoTokens(POOL_TOKENS, data, vaultPropsLength));
  return {
    infoTokens: getInfoTokens(POOL_TOKENS, data, vaultPropsLength),
    chartsData: _result.mlpPool,
    chartsDataNumber: _result.chartsDataNumber,
    chartsDataColor: _result.chartsDataColor,
    chartsDataText: _result.chartsDataText,
  };
};

export const getInfoTokens = (
  whitelistedTokens: TTradeToken,
  vaultTokenInfo?: number[],
  vaultPropsLength = 15,
) => {
  const infoTokens: InfoToken[] = [];
  Object.keys(whitelistedTokens).forEach((value, i: number) => {
    const info: InfoToken = {
      address: value as Address,
      symbol: whitelistedTokens[value as TradeTokenKey],
      imageUrl: `/images/tokens/${whitelistedTokens[value as TradeTokenKey]}.png`,
    };
    if (vaultTokenInfo) {
      info.poolAmount = vaultTokenInfo[i * vaultPropsLength];
      info.reservedAmount = vaultTokenInfo[i * vaultPropsLength + 1];
      info.availableAmount = new BigNumber(info.poolAmount).minus(info.reservedAmount);
      info.usdgAmount = formatDecimalPlaces(
        fromBigNumberAmount(
          vaultTokenInfo[i * vaultPropsLength + 2],
          whitelistedTokens[value as TradeTokenKey] as TApproveKey,
        ),
      );
      info.minPrice = fromBigNumberPrice(vaultTokenInfo[i * vaultPropsLength + 10]);
      info.maxPrice = fromBigNumberPrice(vaultTokenInfo[i * vaultPropsLength + 11]);
      info.guaranteedUsd = formatDecimalPlaces(
        fromBigNumberPrice(vaultTokenInfo[i * vaultPropsLength + 12]),
      );
      info.availableUsd =
        value === process.env.NEXT_PUBLIC_USDT
          ? fromBigNumberAmount(
              new BigNumber(info.poolAmount).times(1),
              whitelistedTokens[value as TradeTokenKey] as TApproveKey,
            )
          : fromBigNumberAmount(
              new BigNumber(info.availableAmount).times(info.minPrice),
              whitelistedTokens[value as TradeTokenKey] as TApproveKey,
            );
      info.managedUsd = formatNumber(
        formatDecimalPlaces(new BigNumber(info.availableUsd).plus(info.guaranteedUsd), 0),
      );
    }
    infoTokens.push(info);
  });
  return infoTokens;
};

export const getPool = (data: InfoToken[]) => {
  let adjustedUsdtSupply = new BigNumber(0);
  for (const token of data) {
    if (token && token.usdgAmount) {
      adjustedUsdtSupply = new BigNumber(adjustedUsdtSupply).plus(token.usdgAmount);
    }
  }
  const mlpPool: ChartsData[] = data.map((token: any) => {
    if (token.usdgAmount && adjustedUsdtSupply && new BigNumber(adjustedUsdtSupply).comparedTo(0)) {
      const currentWeightBps = new BigNumber(token.usdgAmount).times(10000).div(adjustedUsdtSupply);
      return {
        symbol: token.symbol,
        color: TOKENS_COLOR[token.symbol as keyof typeof TOKENS_COLOR],
        value: formatDecimalPlaces(currentWeightBps.div(100)),
      };
    }

    return {
      symbol: token.symbol,
      color: TOKENS_COLOR[token.symbol as keyof typeof TOKENS_COLOR],
      value: '0',
    };
  });
  const chartsDataNumber: number[] = [];
  const chartsDataColor: string[] = [];
  const chartsDataText: string[] = [];
  mlpPool.forEach((item: ChartsData) => {
    chartsDataNumber.push(Number(item.value));
    chartsDataColor.push(item.color);
    chartsDataText.push(item.symbol);
  });
  return {
    mlpPool,
    chartsDataNumber,
    chartsDataColor,
    chartsDataText,
  };
};
