import BigNumber from 'bignumber.js';
import { useEffect, useMemo, useState } from 'react';
import { maxUint256, zeroAddress } from 'viem';
import { Address, useAccount, useBalance } from 'wagmi';

import {
  formatDecimalPlaces,
  fromBigNumberAmount,
  fromBigNumberPrice,
  parseAmount,
  parsePrice,
} from '@/lib/format';
import { getStorage } from '@/lib/storage';
import { useApprove, useDecreaseMarketOrder, useIncreaseMarketOrder } from '@/hooks/contract';

import { useAllowanceStore, useAmountStore, useFeeStore, usePricesStore } from '@/stores';

import { ALL_TOKENS, APPROVE_TOKENS, DECIMAL_SCALE, MAX_VALUE, SLIPPAGES, USDT } from '@/consts';

import { TApproveKey, TradeTokenKey } from '@/types';

export const useAdjust = (onHash: (hash: string) => void, isOpen: boolean, position?: any) => {
  const [type, setType] = useState<'add' | 'minus'>('add');
  const [value, setValue] = useState('');

  const [afterLeverage, setAfterLeverage] = useState('');
  const [afterLiquidationPrice, setAfterLiquidationPrice] = useState('');
  const [afterMargin, setAfterMargin] = useState('');

  const { prices } = usePricesStore();
  const { fundingRates, feeData, marketOrderFee } = useFeeStore();
  const { allowance } = useAllowanceStore();
  const { poolAmounts, reservedAmounts } = useAmountStore();

  const { isLoading: increaseMarketOrderIsLoading, writeAsync: increaseMarketOrderWrite } =
    useIncreaseMarketOrder();
  const { isLoading: decreaseMarketOrderIsLoading, writeAsync: decreaseMarketOrderWrite } =
    useDecreaseMarketOrder();

  const collateralSymbol = useMemo(() => {
    if (!position?.isTokenBased) return 'USDT';
    return position?.indexToken?.symbol ?? Object.values(ALL_TOKENS)[0];
  }, [position?.indexToken?.symbol, position?.isTokenBased]);

  const decimalScale = useMemo(() => {
    return position?.isTokenBased ? DECIMAL_SCALE[1] : DECIMAL_SCALE[0];
  }, [position?.isTokenBased]);

  const { isLoading: approveIsLoading, writeAsync: approveWrite } = useApprove(collateralSymbol);

  const { address } = useAccount();

  const balance = useBalance({
    address,
    token: APPROVE_TOKENS[collateralSymbol as TApproveKey] as Address,
    watch: true,
  });

  const loading = useMemo(() => {
    return increaseMarketOrderIsLoading || decreaseMarketOrderIsLoading || approveIsLoading;
  }, [increaseMarketOrderIsLoading, decreaseMarketOrderIsLoading, approveIsLoading]);

  const positionSize = useMemo(() => {
    if (position?.size && position?.averagePrice) {
      if (!position?.isTokenBased) {
        return formatDecimalPlaces(fromBigNumberPrice(position.size));
      }

      return formatDecimalPlaces(
        new BigNumber(position.size).div(position?.averagePrice),
        decimalScale,
      );
    }

    return '0';
  }, [decimalScale, position?.averagePrice, position?.size, position?.isTokenBased]);

  const collateralAmount = useMemo(() => {
    if (position?.collateral && !position?.isTokenBased) {
      return formatDecimalPlaces(fromBigNumberPrice(position?.collateral));
    } else if (position?.collateral && position?.averagePrice) {
      return formatDecimalPlaces(
        new BigNumber(position.collateral).div(position?.averagePrice),
        decimalScale,
      );
    }

    return '0';
  }, [decimalScale, position?.averagePrice, position?.collateral, position?.isTokenBased]);

  const address_path = useMemo(() => {
    let _path: string[] = [];
    if (!position) return [];
    if (position.isLong && position?.isTokenBased) {
      _path = [position.indexToken.address];
    } else if (!position.isLong && !position?.isTokenBased) {
      _path = [USDT];
    } else if (position.isLong && !position?.isTokenBased) {
      _path =
        type === 'add' ? [USDT, position.indexToken.address] : [position.indexToken.address, USDT];
    } else if (!position.isLong && position?.isTokenBased) {
      _path =
        type === 'add' ? [position.indexToken.address, USDT] : [USDT, position.indexToken.address];
    }
    return _path;
  }, [position, type]);

  const available = useMemo(() => {
    if (type === 'add') {
      return (
        formatDecimalPlaces(new BigNumber(balance.data?.formatted as string), decimalScale) ?? '0'
      );
    }

    return formatDecimalPlaces(new BigNumber(collateralAmount), decimalScale) ?? '0';
  }, [balance.data?.formatted, collateralAmount, decimalScale, type]);

  const marketPrice = useMemo(() => {
    if (!position) return '0.00';
    return prices?.[position.indexToken.address] ?? '0.00';
  }, [prices, position]);

  const openPrice = useMemo(() => {
    return position?.averagePrice
      ? formatDecimalPlaces(fromBigNumberPrice(position?.averagePrice))
      : '0.00';
  }, [position?.averagePrice]);

  const liquidationPrice = useMemo(() => {
    if (position) {
      return formatDecimalPlaces(fromBigNumberPrice(position.liquidatePrice));
    }

    return '0';
  }, [position]);

  const execFee = useMemo(() => {
    const ethPrice = prices?.[APPROVE_TOKENS['ETH' as TApproveKey] as TradeTokenKey];
    const gasPrice = feeData?.gasPrice;
    if (ethPrice && marketOrderFee && gasPrice) {
      const maxFee = Number(marketOrderFee) > Number(gasPrice) ? marketOrderFee : gasPrice;
      const ethFee = new BigNumber(ethPrice).times(Number(maxFee)).times(2150000);
      const result = fromBigNumberAmount(ethFee, 'ETH').toFormat();
      return result;
    }
    return '0';
  }, [feeData?.gasPrice, marketOrderFee, prices]);

  const openFee = useMemo(() => {
    if (Number(value) > 0 && positionSize && position?.size) {
      const limit = Number(value) > Number(positionSize) ? positionSize : value;
      return formatDecimalPlaces(
        new BigNumber(limit)
          .div(positionSize)
          .times(fromBigNumberPrice(position.size))
          .times(0.001),
        4,
      );
    }

    return '0';
  }, [value, positionSize, position?.size]);

  const fundingFee = useMemo(() => {
    if (position?.size && fundingRates) {
      const rate = position?.isLong
        ? fundingRates[position?.indexToken?.address]
        : fundingRates[USDT];
      const fundingRate = rate ? new BigNumber(rate).div(1000000).toString() : '0';
      if (Number(fundingRate) > 0) {
        return formatDecimalPlaces(fromBigNumberPrice(position.size).times(fundingRate), 4);
      }
    }

    return '0';
  }, [fundingRates, position?.indexToken?.address, position?.isLong, position?.size]);

  const afterFundingFee = useMemo(() => {
    if (
      !position?.leverage ||
      !value ||
      !fundingRates ||
      !position?.indexToken?.address ||
      Number(value) <= 0 ||
      Number(value) > Number(available)
    )
      return '';
    const size = position?.isTokenBased
      ? new BigNumber(value).times(position.leverage).times(openPrice)
      : new BigNumber(value);
    const rate = position?.isLong ? fundingRates[position.indexToken.address] : fundingRates[USDT];
    const fundingRate = rate ? new BigNumber(rate).div(1000000).toString() : '0';
    const newFunding = Number(fundingRate) > 0 ? size.times(fundingRate) : new BigNumber(0);
    if (type === 'add') {
      return formatDecimalPlaces(newFunding.plus(fundingFee));
    }

    const result = formatDecimalPlaces(new BigNumber(fundingFee).minus(newFunding));
    return Number(result) > 0 ? result : '0';
  }, [
    openPrice,
    position?.leverage,
    position?.isTokenBased,
    position?.isLong,
    position?.indexToken?.address,
    value,
    available,
    fundingRates,
    type,
    fundingFee,
  ]);

  const totalFee = useMemo(() => {
    return formatDecimalPlaces(new BigNumber(execFee).plus(openFee));
  }, [openFee, execFee]);

  const currentLeverage = useMemo(() => {
    if (position?.leverage) {
      return formatDecimalPlaces(position.leverage);
    }

    return '--';
  }, [position?.leverage]);

  const currentAllowance = useMemo(() => {
    return allowance?.[collateralSymbol as TApproveKey] ?? 0;
  }, [allowance, collateralSymbol]);

  const needApprove = useMemo(() => {
    return (
      value && fromBigNumberAmount(currentAllowance, collateralSymbol as TApproveKey).lt(value)
    );
  }, [currentAllowance, collateralSymbol, value]);

  const isInsufficientBalance = useMemo(() => {
    return !!(value && available && Number(value) > Number(available));
  }, [available, value]);

  const isInsufficientLiquidity = useMemo(() => {
    if (!poolAmounts || !reservedAmounts || !position?.indexToken?.address || !position?.leverage)
      return false;
    const tokenAddress = position?.isTokenBased ? position.indexToken.address : USDT;
    const liquidity = new BigNumber(poolAmounts[tokenAddress]).minus(reservedAmounts[tokenAddress]);
    return (
      Number(value) > 0 &&
      type === 'add' &&
      new BigNumber(value).times(position.leverage).gt(liquidity)
    );
  }, [
    poolAmounts,
    position?.indexToken?.address,
    position?.isTokenBased,
    position?.leverage,
    reservedAmounts,
    type,
    value,
  ]);

  const isSmallCollateralDelta = useMemo(() => {
    if (type === 'minus' || Number(value) <= 0) return false;
    const delta = position?.isTokenBased
      ? new BigNumber(value).times(marketPrice).toString()
      : value;
    return Number(delta) < 10;
  }, [position?.isTokenBased, marketPrice, type, value]);

  const isDisabled = useMemo(() => {
    return (
      !value ||
      Number(value) <= 0 ||
      isInsufficientBalance ||
      isInsufficientLiquidity ||
      isSmallCollateralDelta
      // Number(afterLeverage) < 1.1 ||
      // Number(afterLeverage) > 50
    );
  }, [
    // afterLeverage,
    isInsufficientBalance,
    isInsufficientLiquidity,
    isSmallCollateralDelta,
    value,
  ]);

  useEffect(() => {
    if (!isOpen) {
      setType('add');
      setValue('');
      setAfterLeverage('');
      setAfterLiquidationPrice('');
      setAfterMargin('');
    }
  }, [isOpen]);

  const onPress = (val: 'add' | 'minus') => {
    handleSetValue('');
    setType(val);
  };

  const handleSetValue = (val: string) => {
    const reg = new RegExp(`^\\D*(\\d*(?:\\.\\d{0,${decimalScale}})?).*$`, 'g');
    val = val.replace(reg, '$1');
    if (Number(value) > MAX_VALUE) {
      setValue(MAX_VALUE.toString());
      return;
    }
    setValue(val);
    if (Number(val) > 0 && position?.leverage) {
      const percentage = new BigNumber(val).div(collateralAmount);
      if (type === 'add') {
        // const nextLeverage = new BigNumber(1).minus(percentage).times(position.leverage);
        const nextMargin = new BigNumber(collateralAmount).plus(val);
        // setAfterLeverage(nextLeverage.gt(0) ? formatDecimalPlaces(nextLeverage) : '0');
        setAfterMargin(nextMargin.gt(0) ? formatDecimalPlaces(nextMargin, decimalScale) : '0');
      } else if (Number(val) <= Number(positionSize)) {
        // const nextLeverage = new BigNumber(1).plus(percentage).times(position.leverage);
        const nextMargin = new BigNumber(collateralAmount).minus(val);
        // setAfterLeverage(formatDecimalPlaces(nextLeverage));
        setAfterMargin(nextMargin.gt(0) ? formatDecimalPlaces(nextMargin, decimalScale) : '0');
      } else {
        setAfterLeverage('');
        setAfterLiquidationPrice('');
        setAfterMargin('');
        return;
      }
      if ((type === 'add' && position?.isLong) || (type === 'minus' && !position?.isLong)) {
        const ratio = new BigNumber(1).minus(percentage);
        const nextLiquidationPrice = ratio.times(liquidationPrice);
        setAfterLiquidationPrice(
          nextLiquidationPrice.gt(0) ? formatDecimalPlaces(nextLiquidationPrice) : '0',
        );
      } else {
        const ratio = new BigNumber(1).plus(percentage);
        const nextLiquidationPrice = ratio.times(liquidationPrice);
        setAfterLiquidationPrice(formatDecimalPlaces(nextLiquidationPrice));
      }
    } else {
      setAfterLeverage('');
      setAfterLiquidationPrice('');
      setAfterMargin('');
    }
  };

  const handleAll = () => {
    handleSetValue(available);
  };

  const handleConfirm = async () => {
    if (!position) return;
    const allowedSlippage = getStorage('slippage') ?? SLIPPAGES[0];
    const priceBasisPoints =
      (type === 'add' && position.isLong) || (type === 'minus' && !position.isLong)
        ? new BigNumber(1).plus(allowedSlippage)
        : new BigNumber(1).minus(allowedSlippage);
    const refPrice = prices?.[position.indexToken.address];
    if (!refPrice) return;
    const priceLimit = new BigNumber(refPrice).times(priceBasisPoints).toString();
    if (type === 'add') {
      if (needApprove) {
        await approveWrite({
          args: [process.env.NEXT_PUBLIC_ROUTER_ADDRESS as Address, maxUint256],
        }).catch((err) => {
          console.log(err);
        });
      }

      const sizeData = new BigNumber(value).times(position?.leverage);
      const sizeDelta = position?.isTokenBased ? sizeData.times(marketPrice) : sizeData;

      const { hash } = await increaseMarketOrderWrite({
        args: [
          address_path, // _path
          position.indexToken?.address, // _indexToken: BTC or ETH
          parseAmount(value, collateralSymbol as TApproveKey), // _amountIn
          0, // _minOut
          parsePrice(sizeDelta.toString()), // _sizeDelta: payAmount * leverageMultiple
          position.isLong, // _isLong
          [parsePrice(priceLimit), 0, 0, 0, 0, 0, 0], // params
          marketOrderFee, // _executionFee
          zeroAddress, // _callbackTarget
        ],
        value: marketOrderFee as unknown as bigint,
      }).catch((err) => {
        console.log(err);
        return { hash: '' };
      });
      onHash(hash);
    } else {
      let collateralDelta = value;
      if (position?.isTokenBased) {
        collateralDelta = new BigNumber(collateralDelta)
          .times(fromBigNumberPrice(position.averagePrice))
          .toString();
      }
      const params = [
        address_path,
        position.indexToken?.address,
        parsePrice(collateralDelta),
        0,
        position.isLong,
        parsePrice(priceLimit),
        0,
        marketOrderFee,
        zeroAddress,
      ];

      const { hash } = await decreaseMarketOrderWrite({
        args: params,
        value: marketOrderFee as unknown as bigint,
      }).catch((err) => {
        console.log(err);
        return { hash: '' };
      });
      onHash(hash);
    }
  };

  return {
    type,
    value,
    currentLeverage,
    afterLeverage,
    afterLiquidationPrice,
    afterMargin,
    loading,
    positionSize,
    collateralSymbol,
    collateralAmount,
    available,
    marketPrice,
    openPrice,
    liquidationPrice,
    execFee,
    openFee,
    fundingFee,
    afterFundingFee,
    totalFee,
    isDisabled,
    isInsufficientBalance,
    isInsufficientLiquidity,
    isSmallCollateralDelta,
    onPress,
    handleSetValue,
    handleAll,
    handleConfirm,
  };
};
