import { useCallback, useMemo } from 'react';

import { useEarnInfo } from '@/hooks/api';
import { useMlpStaking } from '@/hooks/contract';

import { useAprStore, usePoolStore } from '@/stores';

import { IAprItem, IListData } from '@/types';

export const usePool = () => {
  const { apr } = useAprStore();
  const { earnInfo } = useEarnInfo();
  const { poolToken } = usePoolStore();
  const { tokenData } = useMlpStaking(poolToken);

  const tokenAPR = useCallback(
    (token: string) => {
      const tokenAPRObj = {
        USDT: 'usdtAPR',
        ETH: 'ethAPR',
        BTC: 'btcAPR',
      };
      const result = tokenAPRObj[token as keyof typeof tokenAPRObj];
      return Number(apr[result as keyof IAprItem]) || 0;
    },
    [apr],
  );
  const tokenExtraAPR = useCallback(
    (token: string) => {
      const tokenAPRObj = {
        USDT: 'usdtExtraAPR',
        ETH: 'ethExtraAPR',
        BTC: 'btcExtraAPR',
      };
      const result = tokenAPRObj[token as keyof typeof tokenAPRObj];
      return Number(apr[result as keyof IAprItem]) || 0;
    },
    [apr],
  );

  const assembleData: IListData[] = useMemo(() => {
    return [
      {
        tokens: 'BTC',
        amount: earnInfo?.BTC?.stakeAmount || '0',
        liquidity: earnInfo?.BTC?.mlpStakeAmount || '0',
        reward: earnInfo?.BTC?.rewardAmount || '0',
        apr: earnInfo?.BTC?.APR || '0',
      },
      {
        tokens: 'ETH',
        amount: earnInfo?.ETH?.stakeAmount || '0',
        liquidity: earnInfo?.ETH?.mlpStakeAmount || '0',
        reward: earnInfo?.ETH?.rewardAmount || '0',
        apr: earnInfo?.ETH?.APR || '0',
      },
      {
        tokens: 'USDT',
        amount: earnInfo?.USDT?.stakeAmount || '0',
        liquidity: earnInfo?.USDT?.mlpStakeAmount || '0',
        reward: earnInfo?.USDT?.rewardAmount || '0',
        apr: earnInfo?.USDT?.APR || '0',
      },
    ];
  }, [
    earnInfo?.BTC?.stakeAmount,
    earnInfo?.BTC?.mlpStakeAmount,
    earnInfo?.BTC?.rewardAmount,
    earnInfo?.BTC?.APR,
    earnInfo?.ETH?.stakeAmount,
    earnInfo?.ETH?.mlpStakeAmount,
    earnInfo?.ETH?.rewardAmount,
    earnInfo?.ETH?.APR,
    earnInfo?.USDT?.stakeAmount,
    earnInfo?.USDT?.mlpStakeAmount,
    earnInfo?.USDT?.rewardAmount,
    earnInfo?.USDT?.APR,
  ]);
  const _data = useMemo(() => {
    return assembleData.filter((item) => Number(item.liquidity) > 0);
  }, [assembleData]);

  return {
    _data,
    tokenAPR,
    tokenExtraAPR,
  };
};
