import { useTranslation } from 'next-i18next';

export const useKineInfo = () => {
  const { t } = useTranslation(['kline']);
  const infoData = {
    BTC: {
      token: 'BTC',
      supply: '157,969',
      data: '2019-01-31',
      contract: process.env.NEXT_PUBLIC_BTC || '',
      introduce: t('BTC-introduce'),
    },
    ETH: {
      token: 'ETH',
      supply: '122,373,866',
      data: '2015-7-30',
      contract: process.env.NEXT_PUBLIC_ETH || '',
      introduce: t('ETH-introduce'),
    },
  };
  return {
    infoData,
  };
};
