import { Address, useContractWrite } from 'wagmi';

import OrderBookABI from '@/abis/OrderBook.json';
import PositionRouterABI from '@/abis/PositionRouter.json';

export const useIncreaseLimitOrder = () => {
  const { isLoading, writeAsync } = useContractWrite({
    address: process.env.NEXT_PUBLIC_ORDER_BOOK_ADDRESS as Address,
    abi: OrderBookABI,
    functionName: 'createIncreaseOrder',
  });

  return {
    isLoading,
    writeAsync,
  };
};

export const useIncreaseMarketOrder = () => {
  const { isLoading, writeAsync } = useContractWrite({
    address: process.env.NEXT_PUBLIC_POSITION_ROUTER_ADDRESS as Address,
    abi: PositionRouterABI,
    functionName: 'createIncreasePosition',
  });

  return {
    isLoading,
    writeAsync,
  };
};
