import BigNumber from 'bignumber.js';
import { Address, useContractRead } from 'wagmi';

import { formatDecimalPlaces, parseAmount } from '@/lib/format';

import { useFeeStore } from '@/stores';

import ReaderABI from '@/abis/Reader.json';
import { ALL_TOKENS } from '@/consts';

import { IVaultAmounts, TApproveKey } from '@/types';

export const useFundingRates = () => {
  const { setFundingRates } = useFeeStore();

  useContractRead({
    address: process.env.NEXT_PUBLIC_READER_ADDRESS as Address,
    abi: ReaderABI,
    functionName: 'getFundingRates',
    args: [
      process.env.NEXT_PUBLIC_VAULT_ADDRESS as Address,
      process.env.NEXT_PUBLIC_ETH as Address,
      Object.keys(ALL_TOKENS),
    ],
    onSuccess(data: number[]) {
      const fundingRates = {} as IVaultAmounts;
      if (data) {
        const len = data.length;
        for (let i = 0; i < len; i++) {
          if (i % 2 == 0) {
            fundingRates[Object.keys(ALL_TOKENS)[i / 2] as Address] = data[i];
          }
        }
      }
      if (Object.keys(fundingRates).length) {
        setFundingRates(fundingRates);
      }
    },
  });
};

export const useFeeBasisPoints = (address_path: string[], amount: string) => {
  const { data }: { data?: number[] } = useContractRead({
    address: process.env.NEXT_PUBLIC_READER_ADDRESS as Address,
    abi: ReaderABI,
    enabled: address_path.length === 2 && Number(amount) > 0,
    functionName: 'getFeeBasisPoints',
    args: [
      process.env.NEXT_PUBLIC_VAULT_ADDRESS as Address,
      address_path[0] as Address,
      address_path[1] as Address,
      parseAmount(amount, ALL_TOKENS[address_path[0] as Address] as TApproveKey),
    ],
  });

  let feeBasisPoints = '0';
  let swapFee = '0';
  if (address_path.length === 2 && Number(amount) > 0 && data && data.length) {
    feeBasisPoints = new BigNumber(data[0]).div(10000).toString();
    swapFee = formatDecimalPlaces(new BigNumber(amount).times(data[0]).div(10000));
  }

  return {
    feeBasisPoints,
    swapFee,
  };
};
