import { useFeeData } from 'wagmi';

import { useInitWriteContractFee } from '@/hooks/contract';

import { useFeeStore } from '@/stores';

export const useInitFee = () => {
  const { setFeeData } = useFeeStore();

  useFeeData({
    onSuccess(data) {
      setFeeData(data);
    },
  });

  useInitWriteContractFee();
};
