import { Address, useContractWrite } from 'wagmi';

import OrderBookABI from '@/abis/OrderBook.json';
import PositionRouterABI from '@/abis/PositionRouter.json';

export const useCancelIncreaseLimitOrder = () => {
  const { isLoading: increaseLimitOrderLoading, writeAsync: cancelIncreaseLimitOrder } =
    useContractWrite({
      address: process.env.NEXT_PUBLIC_ORDER_BOOK_ADDRESS as Address,
      abi: OrderBookABI,
      functionName: 'cancelIncreaseOrder',
    });

  return {
    increaseLimitOrderLoading,
    cancelIncreaseLimitOrder,
  };
};

export const useCancelIncreaseMarketOrder = () => {
  const { isLoading: increaseMarketOrderLoading, writeAsync: cancelIncreaseMarketOrder } =
    useContractWrite({
      address: process.env.NEXT_PUBLIC_POSITION_ROUTER_ADDRESS as Address,
      abi: PositionRouterABI,
      functionName: 'cancelIncreasePosition',
    });
  return {
    cancelIncreaseMarketOrder,
    increaseMarketOrderLoading,
  };
};

export const useCancelDecreasePositionMarketOrder = () => {
  const { isLoading: decreaseMarketOrderLoading, writeAsync: cancelDecreasePositionMarketOrder } =
    useContractWrite({
      address: process.env.NEXT_PUBLIC_POSITION_ROUTER_ADDRESS as Address,
      abi: PositionRouterABI,
      functionName: 'cancelDecreasePosition',
    });
  return {
    cancelDecreasePositionMarketOrder,
    decreaseMarketOrderLoading,
  };
};

export const useCancelDecreaseOrderLimitOrder = () => {
  const { isLoading: decreaseOrderLimitLoading, writeAsync: cancelDecreaseOrderLimitOrder } =
    useContractWrite({
      address: process.env.NEXT_PUBLIC_ORDER_BOOK_ADDRESS as Address,
      abi: OrderBookABI,
      functionName: 'cancelDecreaseOrder',
    });
  return {
    cancelDecreaseOrderLimitOrder,
    decreaseOrderLimitLoading,
  };
};
