import { useMemo, useState } from 'react';

import {
  useCancelDecreaseOrderLimitOrder,
  useCancelIncreaseLimitOrder,
} from '@/hooks/contract/useCancelOrder';

import { CancelOrder } from '@/types';

export const useCancelOrder = () => {
  const { increaseLimitOrderLoading, cancelIncreaseLimitOrder } = useCancelIncreaseLimitOrder();
  const { decreaseOrderLimitLoading, cancelDecreaseOrderLimitOrder } =
    useCancelDecreaseOrderLimitOrder();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [modalData, setModalData] = useState<CancelOrder>({
    isOpen: false,
    hash: '',
    content: '',
  });

  const isLoading = useMemo(() => {
    return increaseLimitOrderLoading || decreaseOrderLimitLoading;
  }, [increaseLimitOrderLoading, decreaseOrderLimitLoading]);

  const handleCancelOrder = async (index: number, type: string) => {
    if (!type) return;
    let data: any = {};
    switch (type) {
      case 'Increase':
        data = await cancelIncreaseLimitOrder({ args: [index] }).catch((err) => {
          console.log(err);
        });
        break;
      case 'Decrease':
        data = await cancelDecreaseOrderLimitOrder({ args: [index] }).catch((err) => {
          console.log(err);
        });
        break;
    }
    if (data?.hash) {
      setModalData({
        isOpen: true,
        hash: data.hash,
        content: '',
      });
    }
  };

  return {
    isLoading,
    currentIndex,
    setCurrentIndex,
    modalData,
    setModalData,
    handleCancelOrder,
  };
};
