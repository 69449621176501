import BigNumber from 'bignumber.js';
import { useEffect, useMemo, useState } from 'react';
import { zeroAddress } from 'viem';

import {
  formatDecimalPlaces,
  fromBigNumberAmount,
  fromBigNumberPrice,
  parsePrice,
  toBigInt,
  toBigNumberPrice,
} from '@/lib/format';
import { getStorage } from '@/lib/storage';
import { useDecreaseLimitOrder, useDecreaseMarketOrder } from '@/hooks/contract';

import { useFeeStore, usePricesStore } from '@/stores';

import {
  ALL_TOKENS,
  APPROVE_TOKENS,
  DECIMAL_SCALE,
  DECREASE_ORDER_EXECUTION_GAS_FEE,
  MAX_VALUE,
  SLIPPAGES,
  USDT,
} from '@/consts';

import { TApproveKey, TradeTokenKey } from '@/types';

export const usePosition = (position?: any) => {
  const [profitRatio, setProfitRatio] = useState('');
  const [profitPrice, setProfitPrice] = useState('');
  const [lossRatio, setLossRatio] = useState('');
  const [lossPrice, setLossPrice] = useState('');

  const [positionLimit, setPositionLimit] = useState('');
  const [sliderValue, setSliderValue] = useState(0);

  const [isKeepLeverage, setIsKeepLeverage] = useState(true);
  const [isHigherSlippageAllowed, setIsHigherSlippageAllowed] = useState(true);
  const [afterLeverage, setAfterLeverage] = useState('');
  const [afterLiquidationPrice, setAfterLiquidationPrice] = useState('');
  const [afterHoldAmount, setAfterHoldAmount] = useState('');
  const [afterMargin, setAfterMargin] = useState('');

  const { prices } = usePricesStore();
  const { fundingRates, feeData, limitOrderFee, marketOrderFee } = useFeeStore();

  const { isLoading: isTradeStopProfitLossLoading, writeAsync: tradeStopProfitLossWrite } =
    useDecreaseLimitOrder();
  const { isLoading: isPartialLiquidationLoading, writeAsync: partialLiquidationWrite } =
    useDecreaseMarketOrder();

  const positionSymbol = useMemo(() => {
    return position?.indexToken?.symbol ?? Object.values(ALL_TOKENS)[0];
  }, [position?.indexToken?.symbol]);

  const collateralSymbol = useMemo(() => {
    if (!position?.isTokenBased) return 'USDT';
    return position?.indexToken?.symbol ?? Object.values(ALL_TOKENS)[0];
  }, [position?.indexToken?.symbol, position?.isTokenBased]);

  const decimalScale = useMemo(() => {
    return position?.isTokenBased ? DECIMAL_SCALE[1] : DECIMAL_SCALE[0];
  }, [position?.isTokenBased]);

  const openPrice = useMemo(() => {
    return position?.averagePrice
      ? formatDecimalPlaces(fromBigNumberPrice(position?.averagePrice))
      : '0.00';
  }, [position?.averagePrice]);

  const collateralAmount = useMemo(() => {
    if (position?.collateral && !position?.isTokenBased) {
      return formatDecimalPlaces(fromBigNumberPrice(position?.collateral), decimalScale);
    } else if (position?.collateral && position?.averagePrice) {
      return formatDecimalPlaces(
        new BigNumber(position.collateral).div(position?.averagePrice),
        decimalScale,
      );
    }

    return '0';
  }, [decimalScale, position?.averagePrice, position?.collateral, position?.isTokenBased]);

  const marketPrice = useMemo(() => {
    if (!position) return '0.00';
    return prices?.[position.indexToken.address] ?? '0.00';
  }, [prices, position]);

  const positionSize = useMemo(() => {
    if (position?.size && position?.averagePrice) {
      return formatDecimalPlaces(new BigNumber(position.size).div(position?.averagePrice), 4);
    }

    return '0';
  }, [position?.size, position?.averagePrice]);

  const liquidationPrice = useMemo(() => {
    if (position) {
      return formatDecimalPlaces(fromBigNumberPrice(position.liquidatePrice));
    }

    return '0';
  }, [position]);

  const currentLeverage = useMemo(() => {
    if (position?.leverage) {
      return formatDecimalPlaces(position.leverage);
    }

    return '--';
  }, [position?.leverage]);

  const isAllPosition = useMemo(() => {
    return positionLimit === positionSize;
  }, [positionLimit, positionSize]);

  const execFee = useMemo(() => {
    const ethPrice = prices?.[APPROVE_TOKENS['ETH' as TApproveKey] as TradeTokenKey];
    const gasPrice = feeData?.gasPrice;
    if (ethPrice && limitOrderFee && gasPrice) {
      const maxFee = Number(limitOrderFee) > Number(gasPrice) ? limitOrderFee : gasPrice;
      const ethFee = new BigNumber(ethPrice).times(Number(maxFee)).times(2150000);
      const result = fromBigNumberAmount(ethFee, 'ETH').toFormat();
      return result;
    }
    return '0';
  }, [feeData?.gasPrice, limitOrderFee, prices]);

  const closeFee = useMemo(() => {
    if (Number(positionLimit) > 0 && positionSize && position?.size) {
      const limit = Number(positionLimit) > Number(positionSize) ? positionSize : positionLimit;
      return formatDecimalPlaces(
        new BigNumber(limit)
          .div(positionSize)
          .times(fromBigNumberPrice(position.size))
          .times(0.001),
        4,
      );
    }

    return '0';
  }, [positionLimit, positionSize, position?.size]);

  const fundingFee = useMemo(() => {
    if (position?.size && fundingRates) {
      const rate = position?.isLong
        ? fundingRates[position?.indexToken?.address]
        : fundingRates[USDT];
      const fundingRate = rate ? new BigNumber(rate).div(1000000).toString() : '0';
      if (Number(fundingRate) > 0) {
        return formatDecimalPlaces(fromBigNumberPrice(position.size).times(fundingRate), 4);
      }
    }

    return '0';
  }, [fundingRates, position?.indexToken?.address, position?.isLong, position?.size]);

  const totalFee = useMemo(() => {
    return formatDecimalPlaces(new BigNumber(execFee).plus(closeFee).plus(fundingFee));
  }, [closeFee, execFee, fundingFee]);

  const isInsufficientBalance = useMemo(() => {
    return !!(positionLimit && Number(positionLimit) > Number(positionSize));
  }, [positionLimit, positionSize]);

  const receiveProfit = useMemo(() => {
    if (
      Number(positionLimit) > 0 &&
      Number(profitPrice) > 0 &&
      Number(openPrice) > 0 &&
      positionSize &&
      position?.size
    ) {
      const diffPrice = new BigNumber(profitPrice).minus(openPrice).abs();
      const priceRatio = diffPrice.div(profitPrice);
      const profitPositionSize = new BigNumber(positionLimit)
        .div(positionSize)
        .times(fromBigNumberPrice(position.size));
      const result = formatDecimalPlaces(priceRatio.times(profitPositionSize), 4);
      const amount =
        Number(result) > 0
          ? position?.isTokenBased
            ? formatDecimalPlaces(new BigNumber(result).div(openPrice), 4)
            : result
          : '0';
      const collateral =
        Number(amount) > 0
          ? position.isTokenBased
            ? fromBigNumberPrice(position?.collateral).div(openPrice)
            : fromBigNumberPrice(position?.collateral)
          : new BigNumber('0');
      return {
        amount,
        value: Number(result) > 0 ? formatDecimalPlaces(collateral.plus(amount), 4) : '0',
      };
    }

    return {
      amount: '0',
      value: '0',
    };
  }, [
    openPrice,
    position?.collateral,
    position?.isTokenBased,
    position?.size,
    positionLimit,
    positionSize,
    profitPrice,
  ]);

  const receiveProfitLoss = useMemo(() => {
    if (
      Number(positionLimit) > 0 &&
      Number(marketPrice) > 0 &&
      Number(openPrice) > 0 &&
      positionSize &&
      position?.size
    ) {
      const diffPrice = new BigNumber(marketPrice).minus(openPrice);
      const priceRatio = diffPrice.div(marketPrice);
      const profitPositionSize = new BigNumber(positionLimit)
        .div(positionSize)
        .times(fromBigNumberPrice(position.size));
      const result = formatDecimalPlaces(priceRatio.times(profitPositionSize), 4);
      const amount = position?.isTokenBased
        ? formatDecimalPlaces(new BigNumber(result).div(openPrice), 4)
        : result;
      const collateral = position.isTokenBased
        ? fromBigNumberPrice(position?.collateral).div(openPrice)
        : fromBigNumberPrice(position?.collateral);
      if (
        (position?.isLong && Number(marketPrice) >= Number(openPrice)) ||
        (!position?.isLong && Number(marketPrice) <= Number(openPrice))
      ) {
        return formatDecimalPlaces(collateral.plus(amount), 4);
      }

      return formatDecimalPlaces(collateral.minus(amount), 4);
    }

    return '0';
  }, [
    marketPrice,
    openPrice,
    position?.collateral,
    position?.isTokenBased,
    position?.isLong,
    position?.size,
    positionLimit,
    positionSize,
  ]);

  const receiveLoss = useMemo(() => {
    if (
      Number(positionLimit) > 0 &&
      Number(lossPrice) > 0 &&
      Number(openPrice) > 0 &&
      positionSize &&
      position?.size
    ) {
      const diffPrice = new BigNumber(lossPrice).minus(openPrice).abs();
      const priceRatio = diffPrice.div(lossPrice);
      const profitPositionSize = new BigNumber(positionLimit)
        .div(positionSize)
        .times(fromBigNumberPrice(position.size));
      const result = formatDecimalPlaces(priceRatio.times(profitPositionSize), 4);
      const amount =
        Number(result) > 0
          ? position?.isTokenBased
            ? formatDecimalPlaces(new BigNumber(result).div(openPrice), 4)
            : result
          : '0';
      const collateral =
        Number(amount) > 0
          ? position.isTokenBased
            ? fromBigNumberPrice(position?.collateral).div(openPrice)
            : fromBigNumberPrice(position?.collateral)
          : new BigNumber('0');
      return {
        amount,
        value: Number(result) > 0 ? formatDecimalPlaces(collateral.minus(amount), 4) : '0',
      };
    }

    return {
      amount: '0',
      value: '0',
    };
  }, [
    openPrice,
    position?.collateral,
    position?.isTokenBased,
    position?.size,
    positionLimit,
    positionSize,
    lossPrice,
  ]);

  useEffect(() => {
    if (
      isKeepLeverage &&
      Number(openPrice) > 0 &&
      position?.leverage &&
      position?.size &&
      position?.collateral &&
      Number(positionLimit) > 0
    ) {
      const newPositionLimit = new BigNumber(positionLimit).times(openPrice).toString();
      const marginRatio = isAllPosition
        ? new BigNumber(1)
        : toBigNumberPrice(newPositionLimit).div(position.size);
      const diffMargin = marginRatio.times(position.collateral);
      const nextMargin = position.isTokenBased
        ? position.collateral.minus(diffMargin).div(position.averagePrice)
        : fromBigNumberPrice(position.collateral.minus(diffMargin));
      setAfterLeverage('');
      setAfterMargin(nextMargin.gt(0) ? formatDecimalPlaces(nextMargin, decimalScale) : '0');
    } else if (!isKeepLeverage && position?.leverage && Number(positionLimit) > 0) {
      const ratio = new BigNumber(1).minus(new BigNumber(positionLimit).div(positionSize));
      const nextLeverage = ratio.times(position.leverage);
      setAfterLeverage(formatDecimalPlaces(nextLeverage));
      setAfterMargin('');
    } else {
      setAfterLeverage('');
      setAfterMargin('');
    }
  }, [isKeepLeverage]);

  const handleAllPosition = () => {
    if (Number(positionSize) > 0) {
      handleSetPositionLimit(positionSize);
    }
  };

  const onChangeSlider = (v: number | number[]) => {
    setSliderValue(v as number);
    const val = (v as number) > 0.995 ? 1 : (v as number);
    const value = formatDecimalPlaces(new BigNumber(Number(positionSize) * val), 4);
    handleSetPositionLimit(value);
  };

  const handleSetPositionLimit = (val: string) => {
    const reg = new RegExp(`^\\D*(\\d*(?:\\.\\d{0,${4}})?).*$`, 'g');
    val = val.replace(reg, '$1');
    if (Number(val) > MAX_VALUE) {
      setPositionLimit(MAX_VALUE.toString());
      return;
    }
    setPositionLimit(val);
    if (
      Number(val) > 0 &&
      Number(openPrice) > 0 &&
      position?.leverage &&
      position?.size &&
      position?.collateral
    ) {
      if (Number(val) <= Number(positionSize)) {
        const ratio = new BigNumber(1).minus(new BigNumber(val).div(positionSize));
        let nextLiquidationPrice = ratio.times(liquidationPrice);
        if (!position?.isLong) {
          const addRatio = new BigNumber(1).plus(new BigNumber(val).div(positionSize));
          nextLiquidationPrice = addRatio.times(liquidationPrice);
        }
        setSliderValue(Number(val) / Number(positionSize));
        if (isKeepLeverage) {
          const newPositionLimit = new BigNumber(val).times(openPrice).toString();
          const marginRatio =
            val === positionSize
              ? new BigNumber(1)
              : toBigNumberPrice(newPositionLimit).div(position.size);
          const diffMargin = marginRatio.times(position.collateral);
          const nextMargin = position.isTokenBased
            ? position.collateral.minus(diffMargin).div(position.averagePrice)
            : fromBigNumberPrice(position.collateral.minus(diffMargin));
          setAfterMargin(nextMargin.gt(0) ? formatDecimalPlaces(nextMargin, decimalScale) : '0');
        } else {
          const nextLeverage = ratio.times(position.leverage);
          setAfterLeverage(formatDecimalPlaces(nextLeverage));
        }
        setAfterLiquidationPrice(formatDecimalPlaces(nextLiquidationPrice));
        setAfterHoldAmount(formatDecimalPlaces(new BigNumber(positionSize).minus(val), 4));
      } else {
        setSliderValue(1);
        setAfterLeverage('');
        setAfterLiquidationPrice('');
        setAfterHoldAmount('');
        setAfterMargin('');
      }
    } else {
      setSliderValue(0);
      setAfterLeverage('');
      setAfterLiquidationPrice('');
      setAfterHoldAmount('');
      setAfterMargin('');
    }
  };

  const tradeStopProfitLoss = async () => {
    if (position) {
      let collateralDelta = new BigNumber(0);
      const newPositionLimit = new BigNumber(positionLimit).times(openPrice).toString();

      if (isKeepLeverage && newPositionLimit && position?.collateral && position?.size) {
        const ratio = toBigNumberPrice(newPositionLimit).div(position.size);
        collateralDelta = ratio.times(position.collateral);
      }

      const sizeData = isAllPosition ? position.size : parsePrice(newPositionLimit);
      const sizeDelta = BigNumber.isBigNumber(sizeData) ? toBigInt(sizeData) : sizeData;

      const isProfit = Number(profitPrice) > 0;
      const isLoss = Number(lossPrice) > 0;

      const params = [
        position?.collateralToken.address,
        position?.indexToken?.address,
        position?.isLong,
        position?.isTokenBased,
        [
          isProfit ? parsePrice(profitPrice) : parsePrice('0'),
          isProfit ? sizeDelta : parsePrice('0'),
          isProfit ? toBigInt(collateralDelta) : parsePrice('0'),
          isLoss ? parsePrice(lossPrice) : parsePrice('0'),
          isLoss ? sizeDelta : parsePrice('0'),
          isLoss ? toBigInt(collateralDelta) : parsePrice('0'),
        ],
      ];
      const { hash } = await tradeStopProfitLossWrite({
        args: params,
        value: DECREASE_ORDER_EXECUTION_GAS_FEE,
      }).catch((err) => {
        console.log(err);
        return { hash: '' };
      });
      return hash;
    }
    return '';
  };

  const partialLiquidation = async (newPosition?: any, isAll = false) => {
    const currentPosition = newPosition ?? position;
    if (currentPosition) {
      let address_path: string[] = [];
      if (currentPosition.isLong && currentPosition?.isTokenBased) {
        address_path = [currentPosition.indexToken.address];
      } else if (!currentPosition.isLong && !currentPosition.isTokenBased) {
        address_path = [USDT];
      } else if (currentPosition.isLong && !currentPosition.isTokenBased) {
        address_path = [currentPosition.indexToken.address, USDT];
      } else if (!currentPosition.isLong && currentPosition.isTokenBased) {
        address_path = [USDT, currentPosition.indexToken.address];
      }

      let collateralDelta = new BigNumber(0);
      const newPositionLimit = new BigNumber(positionLimit)
        .times(fromBigNumberPrice(currentPosition.averagePrice))
        .toString();
      const sizeDelta =
        isAll || isAllPosition ? currentPosition?.size : parsePrice(newPositionLimit);

      let allowedSlippage = getStorage('slippage') ?? SLIPPAGES[0];
      if (isHigherSlippageAllowed) {
        allowedSlippage = 0.01;
      }

      const priceBasisPoints = currentPosition?.isLong
        ? new BigNumber(1).minus(allowedSlippage)
        : new BigNumber(1).plus(allowedSlippage);
      const refPrice = prices?.[currentPosition.indexToken.address];

      if (!refPrice) return '';

      const priceLimit = new BigNumber(refPrice).times(priceBasisPoints);

      if (
        !isAll &&
        !isAllPosition &&
        isKeepLeverage &&
        newPositionLimit &&
        currentPosition?.collateral &&
        currentPosition?.size
      ) {
        const ratio = toBigNumberPrice(newPositionLimit).div(currentPosition.size);
        collateralDelta = ratio.times(currentPosition.collateral);
      }

      const params = [
        address_path,
        currentPosition?.indexToken?.address,
        toBigInt(collateralDelta),
        BigNumber.isBigNumber(sizeDelta) ? toBigInt(sizeDelta) : sizeDelta,
        currentPosition?.isLong,
        parsePrice(priceLimit.toString()),
        0,
        marketOrderFee,
        zeroAddress,
      ];

      const { hash } = await partialLiquidationWrite({
        args: params,
        value: marketOrderFee as unknown as bigint,
      }).catch((err) => {
        console.log(err);
        return { hash: '' };
      });
      return hash;
    }

    return '';
  };

  return {
    positionLimit,
    setPositionLimit,
    sliderValue,
    setSliderValue,
    isKeepLeverage,
    setIsKeepLeverage,
    isHigherSlippageAllowed,
    setIsHigherSlippageAllowed,
    currentLeverage,
    afterLeverage,
    setAfterLeverage,
    afterLiquidationPrice,
    setAfterLiquidationPrice,
    afterHoldAmount,
    setAfterHoldAmount,
    afterMargin,
    setAfterMargin,
    positionSymbol,
    collateralSymbol,
    collateralAmount,
    marketPrice,
    openPrice,
    positionSize,
    liquidationPrice,
    execFee,
    closeFee,
    fundingFee,
    totalFee,
    isTradeStopProfitLossLoading,
    isPartialLiquidationLoading,
    profitRatio,
    setProfitRatio,
    profitPrice,
    setProfitPrice,
    lossRatio,
    setLossRatio,
    lossPrice,
    setLossPrice,
    receiveProfit,
    receiveLoss,
    receiveProfitLoss,
    isInsufficientBalance,
    onChangeSlider,
    handleAllPosition,
    handleSetPositionLimit,
    tradeStopProfitLoss,
    partialLiquidation,
  };
};
