import { useEffect } from 'react';
import { useAccount, useConnect, useNetwork, useSwitchNetwork } from 'wagmi';
import { InjectedConnector } from 'wagmi/connectors/injected';

import { CHAIN } from '@/consts';

export const useInitNetwork = () => {
  const chain_id = process.env.NEXT_PUBLIC_CHAIN_ID;

  const { isConnected } = useAccount();
  const { connect } = useConnect({
    connector: new InjectedConnector({
      chains: [CHAIN],
    }),
  });
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();

  useEffect(() => {
    if (chain_id && chain?.id && chain?.id !== Number(chain_id)) {
      switchNetwork?.(Number(chain_id));
    }
  }, [chain?.id]);

  useEffect(() => {
    if (!isConnected) {
      connect();
    }
  }, [isConnected]);
};
