import BigNumber from 'bignumber.js';
import { encodePacked, keccak256, parseEther, zeroAddress } from 'viem';
import { Address } from 'wagmi';

import { toBigNumberPrice } from '@/lib/format';

import { collateralTokensArr, indexTokensArr, isLongArr, isTokenBasedArr } from '@/consts';

import { formatDecimalPlaces } from './format';

import { InfoToken, Token, TokenInfo } from '@/types';
export const MARGIN_FEE_BASIS_POINTS = 10;
const UPDATED_POSITION_VALID_DURATION = 60 * 1000;
export const FUNDING_RATE_PRECISION = 1000000;
export const BASIS_POINTS_DIVISOR = 10000;
export const USD_DECIMALS = 30;
export const MAX_PRICE_DEVIATION_BASIS_POINTS = 750;
export const DEFAULT_MAX_USDG_AMOUNT = toBigNumberPrice(200 * 1000 * 1000, 18);
export const LIQUIDATION_FEE = toBigNumberPrice(5, USD_DECIMALS);
export const MAX_LEVERAGE = 100 * BASIS_POINTS_DIVISOR;

export const TOKENS: { [chainId: string]: Token[] } = {
  [process.env.NEXT_PUBLIC_CHAIN_ID as string]: [
    {
      name: 'Bitcoin',
      symbol: 'BTC',
      decimals: 18,
      address: process.env.NEXT_PUBLIC_BTC!,
      isShortable: true,
      imageUrl:
        'https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744',
    },
    {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
      address: process.env.NEXT_PUBLIC_ETH!,
      isNative: true,
      isShortable: true,
      imageUrl: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
    },
    {
      name: 'Tether',
      symbol: 'USDT',
      decimals: 18,
      address: process.env.NEXT_PUBLIC_USDT!,
      isStable: true,
      imageUrl: 'https://assets.coingecko.com/coins/images/325/thumb/Tether-logo.png?1598003707',
    },
  ],
};

const constants = {
  nativeTokenSymbol: 'ETH',
  wrappedTokenSymbol: 'WETH',
  defaultCollateralSymbol: 'BTC',
  defaultFlagOrdersEnabled: false,
  positionReaderPropsLength: 9,
  v2: true,
  SWAP_ORDER_EXECUTION_GAS_FEE: parseEther('0.0003'),
  INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther('0.0003'),
  DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther('0.000300001'),
};

export function getFundingFee(data: {
  size: BigNumber;
  entryFundingRate?: BigNumber;
  cumulativeFundingRate?: BigNumber;
}) {
  const { entryFundingRate, cumulativeFundingRate, size } = data;

  if (entryFundingRate && cumulativeFundingRate) {
    return size
      .multipliedBy(cumulativeFundingRate.minus(entryFundingRate))
      .div(FUNDING_RATE_PRECISION);
  }

  return;
}

const getTokenAddress = (token: TokenInfo) => {
  return token.address;
};

export function getWhitelistedTokens(chainId: string) {
  return TOKENS[chainId].filter((token) => token.symbol !== 'USDG');
}

export function getPositionQuery(tokens: Token[]) {
  const collateralTokens = [];
  const indexTokens = [];
  const isLong = [];

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];
    if (token.isStable) {
      continue;
    }
    if (token.isWrapped) {
      continue;
    }
    collateralTokens.push(getTokenAddress(token));
    indexTokens.push(getTokenAddress(token));
    isLong.push(true);
  }

  for (let i = 0; i < tokens.length; i++) {
    const stableToken = tokens[i];
    if (!stableToken.isStable) {
      continue;
    }

    for (let j = 0; j < tokens.length; j++) {
      const token = tokens[j];
      if (token.isStable) {
        continue;
      }
      if (token.isWrapped) {
        continue;
      }
      collateralTokens.push(stableToken.address);
      indexTokens.push(getTokenAddress(token));
      isLong.push(false);
    }
  }
  return { collateralTokens, indexTokens, isLong };
}

export function getTokenInfo(infoTokens: InfoToken[], tokenAddress: string) {
  return infoTokens?.find((token: InfoToken) => token.address === tokenAddress);
}

export function getPositionKey(
  account: Address,
  collateralTokenAddress: string,
  indexTokenAddress: string,
  isLong: boolean,
  isTokenBased: boolean,
) {
  return (
    account +
    ':' +
    collateralTokenAddress +
    ':' +
    indexTokenAddress +
    ':' +
    isLong +
    ':' +
    isTokenBased
  );
}

export function getPositionContractKey(
  account: Address,
  collateralToken: Address,
  indexToken: Address,
  isLong: boolean,
) {
  return keccak256(
    encodePacked(
      ['address', 'address', 'address', 'bool'],
      [account, collateralToken, indexToken, isLong],
    ),
  );
}

export const limitDecimals = (amount: string, maxDecimals?: number) => {
  let amountStr = amount.toString();
  if (maxDecimals === undefined) {
    return amountStr;
  }
  if (maxDecimals === 0) {
    return amountStr.split('.')[0];
  }
  const dotIndex = amountStr.indexOf('.');
  if (dotIndex !== -1) {
    const decimals = amountStr.length - dotIndex - 1;
    if (decimals > maxDecimals) {
      amountStr = amountStr.substring(0, amountStr.length - (decimals - maxDecimals));
    }
  }
  return amountStr;
};

export const padDecimals = (amount: string, minDecimals: number) => {
  let amountStr = amount.toString();
  const dotIndex = amountStr.indexOf('.');
  if (dotIndex !== -1) {
    const decimals = amountStr.length - dotIndex - 1;
    if (decimals < minDecimals) {
      amountStr = amountStr.padEnd(amountStr.length + (minDecimals - decimals), '0');
    }
  } else {
    amountStr = amountStr + '.0000';
  }
  return amountStr;
};

export function numberWithCommas(x: string) {
  if (!x) {
    return '-';
  }
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

export const formatAmount = (
  amount: BigNumber | undefined,
  tokenDecimals: number,
  displayDecimals?: number,
  useCommas?: boolean,
  defaultValue?: string,
) => {
  if (!defaultValue) {
    defaultValue = '...';
  }
  if (amount === undefined || amount.toString().length === 0) {
    return defaultValue;
  }
  if (displayDecimals === undefined) {
    displayDecimals = 4;
  }
  let amountStr: string = amount.div(new BigNumber(10).pow(tokenDecimals)).toString();
  amountStr = limitDecimals(amountStr, displayDecimals);
  if (displayDecimals !== 0) {
    amountStr = padDecimals(amountStr, displayDecimals);
  }
  if (useCommas) {
    return numberWithCommas(amountStr);
  }
  return amountStr;
};

export const getDeltaStr = (delta: BigNumber, deltaPercentage: BigNumber, hasProfit: boolean) => {
  let deltaStr;
  let deltaPercentageStr;
  if (delta.gt(0)) {
    deltaStr = hasProfit ? '+' : '-';
    deltaPercentageStr = hasProfit ? '+' : '-';
  } else {
    deltaStr = '';
    deltaPercentageStr = '';
  }
  deltaStr += `$${formatAmount(delta, USD_DECIMALS, 2, true)}`;
  deltaPercentageStr += `${formatAmount(deltaPercentage, 2, 2)}%`;
  return { deltaStr, deltaPercentageStr, hasProfit };
};

export function getTokens(chainId: string) {
  return TOKENS[chainId];
}

interface LeverageType {
  size: any;
  sizeDelta: any;
  increaseSize: any;
  collateral: any;
  collateralDelta: any;
  increaseCollateral: any;
  entryFundingRate: any;
  cumulativeFundingRate: any;
  hasProfit: any;
  delta: any;
  includeDelta: any;
}

export function getLeverage({
  size,
  sizeDelta,
  increaseSize,
  collateral,
  collateralDelta,
  increaseCollateral,
  entryFundingRate,
  cumulativeFundingRate,
  hasProfit,
  delta,
  includeDelta,
}: LeverageType) {
  if (!size && !sizeDelta) {
    return;
  }
  if (!collateral && !collateralDelta) {
    return;
  }

  let nextSize = size ? size : BigNumber(0);
  if (sizeDelta) {
    if (increaseSize) {
      nextSize = size.plus(sizeDelta);
    } else {
      if (sizeDelta.gte(size)) {
        return;
      }
      nextSize = size.minus(sizeDelta);
    }
  }

  let remainingCollateral = collateral ? collateral : BigNumber(0);
  if (collateralDelta) {
    if (increaseCollateral) {
      remainingCollateral = collateral.plus(collateralDelta);
    } else {
      if (collateralDelta.gte(collateral)) {
        return;
      }
      remainingCollateral = collateral.minus(collateralDelta);
    }
  }

  if (delta && includeDelta) {
    if (hasProfit) {
      remainingCollateral = remainingCollateral.plus(delta);
    } else {
      if (delta.gt(remainingCollateral)) {
        return;
      }

      remainingCollateral = remainingCollateral.minus(delta);
    }
  }

  if (remainingCollateral.eq(0)) {
    return;
  }

  remainingCollateral = sizeDelta
    ? remainingCollateral
        .multipliedBy(BASIS_POINTS_DIVISOR - MARGIN_FEE_BASIS_POINTS)
        .div(BASIS_POINTS_DIVISOR)
    : remainingCollateral;
  if (entryFundingRate && cumulativeFundingRate) {
    const fundingFee = size
      .multipliedBy(cumulativeFundingRate.minus(entryFundingRate))
      .div(FUNDING_RATE_PRECISION);
    remainingCollateral = remainingCollateral.minus(fundingFee);
  }

  return nextSize.multipliedBy(BASIS_POINTS_DIVISOR).div(remainingCollateral);
}

export function getPositions(
  positionData: any,
  tokenMap: any,
  infoTokens: any,
  account: any,
  liquidateFee: any,
) {
  const propsLength = 9;
  const positions: any[] = [];
  const positionsMap: { [key: string]: any } = {};
  if (!positionData || infoTokens.length <= 0) {
    return { positions, positionsMap };
  }

  for (let i = 0; i < collateralTokensArr.length; i++) {
    if (BigNumber(positionData[i * propsLength]).eq(0)) {
      continue;
    }
    const collateralToken = getTokenInfo(infoTokens, collateralTokensArr[i]!);
    const indexToken = getTokenInfo(infoTokens, indexTokensArr[i]!);
    const key = getPositionKey(
      account,
      collateralTokensArr[i]!,
      indexTokensArr[i]!,
      isLongArr[i]!,
      isTokenBasedArr[i]!,
    );

    const position = {
      key,
      collateralToken,
      indexToken,
      isLong: isLongArr[i],
      isTokenBased: isTokenBasedArr[i],
      size: BigNumber(positionData[i * propsLength]),
      collateral: BigNumber(positionData[i * propsLength + 1]),
      averagePrice: BigNumber(positionData[i * propsLength + 2]),
      entryFundingRate: BigNumber(positionData[i * propsLength + 3]),
      hasRealisedProfit: BigNumber(positionData[i * propsLength + 4]).eq(1),
      marketPrice: BigNumber(positionData[i * propsLength + 5]),
      lastIncreasedTime: BigNumber(positionData[i * propsLength + 6]).toNumber(),
      hasProfit: BigNumber(positionData[i * propsLength + 7]).eq(1),
      delta: BigNumber(positionData[i * propsLength + 8]),
      leverage: BigNumber(0),
      deltaPercentage: BigNumber(0),
      deltaPercentageStr: '',
      borrowedFee: BigNumber(0),
      positionFee: BigNumber(0),
      netValue: BigNumber(0),
      liquidatePrice: BigNumber(0),
    };
    position.leverage = position.size.div(position.collateral);
    position.deltaPercentage = position.delta.div(position.collateral);
    position.deltaPercentageStr =
      (position.hasProfit ? '+' : '-') +
      formatDecimalPlaces(position.deltaPercentage.multipliedBy(100)) +
      '%';
    position.borrowedFee = tokenMap[key].fundingFee;
    position.positionFee = BigNumber(tokenMap[key].positionFee);
    if (position.hasProfit) {
      position.netValue = position.collateral
        .plus(position.delta)
        .minus(position.borrowedFee)
        .minus(position.positionFee);
    } else {
      position.netValue = position.collateral
        .minus(position.delta)
        .minus(position.borrowedFee)
        .minus(position.positionFee);
    }
    if (position.isTokenBased) {
      position.netValue = position.netValue.div(position.averagePrice);
    }
    // const newLiquidateFee = tokenMap[key].liquidationFeeUSD;
    // const delta = position.hasProfit
    //   ? position.delta
    //   : position.delta.minus(position.delta.multipliedBy(2));
    const netCollateral = position.collateral
      .minus(liquidateFee)
      .minus(position.positionFee)
      .minus(position.borrowedFee);
    const priceDeltaA = netCollateral.multipliedBy(position.averagePrice).div(position.size);
    const priceDeltaB = position.collateral
      .minus(position.size.multipliedBy(tokenMap[key].marginRatio?.[1] ?? 1).div(10000))
      .multipliedBy(position.averagePrice)
      .div(position.size);
    const liquidatePriceA = position.averagePrice.plus(
      position.isLong ? priceDeltaA.minus(priceDeltaA.multipliedBy(2)) : priceDeltaA,
    );
    const liquidatePriceB = position.averagePrice.plus(
      position.isLong ? priceDeltaB.minus(priceDeltaB.multipliedBy(2)) : priceDeltaB,
    );
    const realLiquidatePrice = liquidatePriceA.gt(liquidatePriceB)
      ? position.isLong
        ? liquidatePriceA
        : liquidatePriceB
      : position.isLong
        ? liquidatePriceB
        : liquidatePriceA;
    position.liquidatePrice = realLiquidatePrice;
    positionsMap[key] = position;
    positions.push(position);
  }
  positions.sort((a, b) => b.lastIncreasedTime - a.lastIncreasedTime);
  return { positions, positionsMap };
}

export const getRange = (to: number, from?: number) => {
  const LIMIT = 10;
  const _indexes: number[] = [];
  from = from || Math.max(to - LIMIT, 0);
  for (let i = to - 1; i >= from; i--) {
    _indexes.push(i);
  }
  return _indexes;
};

export const getIndexes = (knownIndexes: any, lastIndex: any) => {
  if (knownIndexes.length === 0) {
    return getRange(lastIndex);
  }
  return [
    ...knownIndexes,
    ...getRange(lastIndex, knownIndexes[knownIndexes.length - 1] + 1).sort((a, b) => b - a),
  ];
};

export function _parseOrdersData(
  ordersData: any,
  account: any,
  indexes: any,
  extractor: any,
  uintPropsLength: any,
  addressPropsLength: any,
) {
  if (!ordersData || ordersData.length === 0) {
    return [];
  }
  const [uintProps, addressProps] = ordersData;
  const count = uintProps.length / uintPropsLength;

  const orders: any[] = [];
  for (let i = 0; i < count; i++) {
    const sliced = addressProps
      .slice(addressPropsLength * i, addressPropsLength * (i + 1))
      .concat(uintProps.slice(uintPropsLength * i, uintPropsLength * (i + 1)));

    if (sliced[0] === zeroAddress && sliced[1] === zeroAddress) {
      continue;
    }

    const order = extractor(sliced);
    order.index = indexes[i];
    order.account = account;
    orders.push(order);
  }

  return orders;
}

export function parseSwapOrdersData(swapOrdersData: any, account: any, indexes: any) {
  if (!swapOrdersData || !swapOrdersData.length) {
    return [];
  }

  const extractor = (sliced: any) => {
    const triggerAboveThreshold = sliced[6].toString() === '1';
    const shouldUnwrap = sliced[7].toString() === '1';

    return {
      path: [sliced[0], sliced[1], sliced[2]].filter((address) => address !== zeroAddress),
      amountIn: sliced[3],
      minOut: sliced[4],
      triggerRatio: sliced[5],
      triggerAboveThreshold,
      type: 'Swap',
      shouldUnwrap,
    };
  };
  return _parseOrdersData(swapOrdersData, account, indexes, extractor, 5, 3).filter((order) => {
    return order.path.every(() => true);
  });
}

export function parseDecreaseOrdersData(decreaseOrdersData: any, account: any, indexes: any) {
  const extractor = (sliced: any) => {
    const isLong = sliced[4].toString() === '1';
    return {
      collateralToken: sliced[0],
      indexToken: sliced[1],
      collateralDelta: sliced[2],
      sizeDelta: sliced[3],
      isLong,
      triggerPrice: sliced[5],
      triggerAboveThreshold: sliced[6].toString() === '1',
      type: 'Decrease',
    };
  };
  return _parseOrdersData(decreaseOrdersData, account, indexes, extractor, 5, 2).filter(() => {
    return true;
  });
}
export function parseDecreaseOrdersDataV2(decreaseIndexes: any, indexes: any) {
  if (!decreaseIndexes || decreaseIndexes.length === 0) {
    return [];
  }
  const [uintProps, addressProps] = decreaseIndexes;
  const propsLength = 7;
  const addressLength = 2;
  const count = uintProps.length / propsLength;
  const orders: any = [];
  for (let i = 0; i < count; i++) {
    if (
      addressProps[i * addressLength + 1] === zeroAddress &&
      BigNumber(uintProps[i * propsLength + 5]).eq(0)
    ) {
      continue;
    }
    const order = {
      purchaseTokenAmount: BigNumber(uintProps[i * propsLength + 1]),
      sizeDelta: BigNumber(uintProps[i * propsLength + 1]),
      isLong: BigNumber(uintProps[i * propsLength + 2]).eq(1),
      isTokenBased: BigNumber(uintProps[i * propsLength + 3]).eq(1),
      openPrice: BigNumber(uintProps[i * propsLength + 4]),
      triggerPrice: BigNumber(uintProps[i * propsLength + 5]),
      triggerAboveThreshold: BigNumber(uintProps[i * propsLength + 6]).eq(1),
      collateralToken: addressProps[i * addressLength],
      indexToken: addressProps[i * addressLength + 1],
      index: indexes[i],
      type: 'Decrease',
    };
    orders.push(order);
  }

  return orders;
}
export function parseIncreaseOrdersData(increaseOrdersData: any, account: any, indexes: any) {
  const extractor = (sliced: any) => {
    const isLong = sliced[5].toString() === '1';
    return {
      purchaseToken: sliced[0],
      collateralToken: sliced[1],
      indexToken: sliced[2],
      purchaseTokenAmount: sliced[3],
      sizeDelta: sliced[4],
      isLong,
      triggerPrice: sliced[6],
      triggerAboveThreshold: sliced[7].toString() === '1',
      type: 'Increase',
    };
  };
  return _parseOrdersData(increaseOrdersData, account, indexes, extractor, 5, 3).filter(() => {
    return true;
  });
}
export function parseIncreaseOrdersDataV2(increaseOrdersData: any, indexes: any) {
  if (!increaseOrdersData || increaseOrdersData.length === 0) {
    return [];
  }
  const [uintProps, addressProps] = increaseOrdersData;
  const propsLength = 7;
  const addressLength = 3;
  const count = uintProps.length / propsLength;
  const orders: any = [];
  for (let i = 0; i < count; i++) {
    if (
      addressProps[i * addressLength + 1] === zeroAddress &&
      BigNumber(uintProps[i * propsLength + 5]).eq(0)
    ) {
      continue;
    }
    const order = {
      purchaseTokenAmount: BigNumber(uintProps[i * propsLength]),
      sizeDelta: BigNumber(uintProps[i * propsLength + 1]),
      isLong: BigNumber(uintProps[i * propsLength + 2]).eq(1),
      isTokenBased: BigNumber(uintProps[i * propsLength + 3]).eq(1),
      openPrice: BigNumber(uintProps[i * propsLength + 4]),
      triggerPrice: BigNumber(uintProps[i * propsLength + 5]),
      triggerAboveThreshold: BigNumber(uintProps[i * propsLength + 6]).eq(1),
      collateralToken: addressProps[i * addressLength],
      indexToken: addressProps[i * addressLength + 2],
      index: indexes[i],
      type: 'Increase',
    };
    orders.push(order);
  }

  return orders;
}
export function getPositionForOrder(account: any, order: any, positionsMap: any) {
  const key = getPositionKey(
    account,
    order.collateralToken,
    order.indexToken,
    order.isLong,
    order.isTokenBased,
  );
  const position = positionsMap[key];
  return position && position.size && position.size.gt(0) ? position : null;
}

export function getOrderError(account: any, order: any, positionsMap: any, position: any) {
  if (order.type !== 'Decrease') {
    return;
  }

  const positionForOrder = position ? position : getPositionForOrder(account, order, positionsMap);
  if (!positionForOrder) {
    return 'No open position, order cannot be executed unless a position is opened';
  }
  if (positionForOrder.size.lt(order.sizeDelta)) {
    return 'Order size is bigger than position, will only be executable if position increases';
  }

  if (positionForOrder.size.gt(order.sizeDelta)) {
    if (
      positionForOrder.size
        .minus(order.sizeDelta)
        .lt(positionForOrder.collateral.minus(order.collateralDelta))
    ) {
      return 'Order cannot be executed as it would reduce the position leverage below 1';
    }
    if (positionForOrder.size.minus(order.sizeDelta).lt(toBigNumberPrice(5, USD_DECIMALS))) {
      return 'Order cannot be executed as the remaining position would be smaller than $5.00';
    }
  }
}

export const getOrdersForPosition = (account: any, position: any, orders: any) => {
  if (!orders || orders.length === 0) {
    return [];
  }

  return orders
    .filter((order: any) => {
      if (order.type === 'Swap') {
        return false;
      }
      const hasMatchingIndexToken = order.indexToken === position.indexToken.address;
      const hasMatchingCollateralToken = order.collateralToken === position.collateralToken.address;
      if (order.isLong === position.isLong && hasMatchingIndexToken && hasMatchingCollateralToken) {
        return true;
      }
    })
    .map((order: any) => {
      order.error = getOrderError(account, order, undefined, position);
      if (order.type === 'Decrease' && order.sizeDelta.gt(position.size)) {
        order.error =
          'Order size is bigger than position, will only be executable if position increases';
      }
      return order;
    });
};

export function getMarginFee(sizeDelta: any) {
  if (!sizeDelta) {
    return 0;
  }
  const afterFeeUsd = sizeDelta
    .multipliedBy(BASIS_POINTS_DIVISOR - MARGIN_FEE_BASIS_POINTS)
    .div(BASIS_POINTS_DIVISOR);
  return sizeDelta.minus(afterFeeUsd);
}

interface Parasm {
  liquidationAmount: any;
  size: any;
  collateral: any;
  averagePrice: any;
  isLong: any;
}
export function getLiquidationPriceFromDelta({
  liquidationAmount,
  size,
  collateral,
  averagePrice,
  isLong,
}: Parasm) {
  if (!size || size.eq(0)) {
    return;
  }

  if (liquidationAmount.gt(collateral)) {
    const liquidationDelta = liquidationAmount.minus(collateral);
    const priceDelta = liquidationDelta.multipliedBy(averagePrice).div(size);

    return isLong ? averagePrice.plus(priceDelta) : averagePrice.minus(priceDelta);
  }

  const liquidationDelta = collateral.minus(liquidationAmount);
  const priceDelta = liquidationDelta.multipliedBy(averagePrice).div(size);

  return isLong ? averagePrice.minus(priceDelta) : averagePrice.plus(priceDelta);
}

export function getLiquidationPrice(data: any) {
  const {
    isLong,
    size,
    collateral,
    averagePrice,
    entryFundingRate,
    cumulativeFundingRate,
    sizeDelta,
    collateralDelta,
    increaseCollateral,
    increaseSize,
    delta,
    hasProfit,
    includeDelta,
  } = data;

  if (!size || !collateral || !averagePrice) {
    return 0;
  }

  let nextSize = size ? size : 0;
  let remainingCollateral = collateral;

  if (sizeDelta) {
    if (increaseSize) {
      nextSize = size.plus(sizeDelta);
    } else {
      if (sizeDelta.gte(size)) {
        return 0;
      }
      nextSize = size.minus(sizeDelta);
    }

    const marginFee = getMarginFee(sizeDelta);
    remainingCollateral = remainingCollateral.minus(marginFee);

    if (includeDelta && !hasProfit) {
      const adjustedDelta = sizeDelta.multipliedBy(delta).div(size);
      remainingCollateral = remainingCollateral.minus(adjustedDelta);
    }
  }

  if (collateralDelta) {
    if (increaseCollateral) {
      remainingCollateral = remainingCollateral.plus(collateralDelta);
    } else {
      if (collateralDelta.gte(remainingCollateral)) {
        return 0;
      }
      remainingCollateral = remainingCollateral.minus(collateralDelta);
    }
  }

  let positionFee = getMarginFee(size).plus(LIQUIDATION_FEE);

  if (entryFundingRate && cumulativeFundingRate) {
    const fundingFee = size
      .multipliedBy(cumulativeFundingRate.minus(entryFundingRate))
      .div(FUNDING_RATE_PRECISION);
    positionFee = positionFee.plus(fundingFee);
  }

  const liquidationPriceForFees = getLiquidationPriceFromDelta({
    liquidationAmount: positionFee,
    size: nextSize,
    collateral: remainingCollateral,
    averagePrice,
    isLong,
  });

  const liquidationPriceForMaxLeverage = getLiquidationPriceFromDelta({
    liquidationAmount: nextSize.multipliedBy(BASIS_POINTS_DIVISOR).div(MAX_LEVERAGE),
    size: nextSize,
    collateral: remainingCollateral,
    averagePrice,
    isLong,
  });

  if (!liquidationPriceForFees) {
    return liquidationPriceForMaxLeverage;
  }

  if (!liquidationPriceForMaxLeverage) {
    return liquidationPriceForFees;
  }

  if (isLong) {
    // return the higher price
    return liquidationPriceForFees.gt(liquidationPriceForMaxLeverage)
      ? liquidationPriceForFees
      : liquidationPriceForMaxLeverage;
  }

  // return the lower price
  return liquidationPriceForFees.lt(liquidationPriceForMaxLeverage)
    ? liquidationPriceForFees
    : liquidationPriceForMaxLeverage;
}

export function getLiquidationPriceV2(data: any) {
  const { isLong, averagePrice, leverage } = data;
  if (isLong) {
    return averagePrice.multipliedBy(new BigNumber(1).minus(new BigNumber(1).div(leverage)));
  }
  return averagePrice.multipliedBy(new BigNumber(1).plus(new BigNumber(1).div(leverage)));
}
