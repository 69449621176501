import BigNumber from 'bignumber.js';
import { useEffect, useMemo, useState } from 'react';
import { maxUint256, zeroAddress } from 'viem';
import { Address, useAccount, useBalance } from 'wagmi';

import {
  formatDecimalPlaces,
  fromBigNumberAmount,
  fromBigNumberPrice,
  parseAmount,
  parsePrice,
} from '@/lib/format';
import { getStorage } from '@/lib/storage';
import { useLiquidationPrice } from '@/hooks/api';
import {
  useApprove,
  useFeeBasisPoints,
  useIncreaseLimitOrder,
  useIncreaseMarketOrder,
} from '@/hooks/contract';

import {
  useAllowanceStore,
  useAmountStore,
  useAppStore,
  useFeeStore,
  usePricesStore,
  useTradeStore,
} from '@/stores';

import {
  ALL_TOKENS,
  APPROVE_TOKENS,
  DECIMAL_SCALE,
  MAX_SLIPPAGE,
  SLIPPAGES,
  TOKENS_DECIMALS,
  TRADE_TOKENS,
  USDT,
} from '@/consts';

import {
  OrderType,
  StopProfitLossType,
  StopType,
  TApproveKey,
  TradeTokenKey,
  TradeTokenType,
  TradeType,
} from '@/types';

const stopProfitLossDefaultValue = {
  isOpen: false,
  type: StopType.TakeProfit,
  takeProfit: {
    value: '',
    ratio: '',
  },
  stopLoss: {
    value: '',
    ratio: '',
  },
};

export const useTrade = () => {
  const {
    tradeType,
    orderType,
    leverageMultiple,
    tradeToken,
    tradeTokenType,
    checkedStopLimit,
    setOrderType,
    setCheckedStopLimit,
  } = useTradeStore();

  const [isLeverOpen, setIsLeverOpen] = useState(false);
  const [showTips, setShowTips] = useState(false);
  const [showFeedBack, setShowFeedBack] = useState(false);
  const [showSlippageWarning, setShowSlippageWarning] = useState(false);

  const [limitPrice, setLimitPrice] = useState('');
  const [percentage, setPercentage] = useState(0);
  const [payAmount, setPayAmount] = useState('');
  const [hash, setHash] = useState('');

  const { setSettingSlippageIsOpen } = useAppStore();
  const { prices } = usePricesStore();
  const { poolAmounts, reservedAmounts } = useAmountStore();

  const { address } = useAccount();

  const { fundingRates, feeData, limitOrderFee, marketOrderFee } = useFeeStore();
  const { allowance } = useAllowanceStore();

  const [stopProfitLossValue, setStopProfitLossValue] = useState<StopProfitLossType>(
    stopProfitLossDefaultValue,
  );

  const { isLoading: increaseLimitOrderIsLoading, writeAsync: increaseLimitOrderWrite } =
    useIncreaseLimitOrder();

  const { isLoading: increaseMarketOrderIsLoading, writeAsync: increaseMarketOrderWrite } =
    useIncreaseMarketOrder();

  const { data: liquidationPrice, run, cancel, mutate } = useLiquidationPrice();

  const currentFundingRate = useMemo(() => {
    const rate = tradeType === TradeType.Long ? fundingRates?.[tradeToken] : fundingRates?.[USDT];
    return {
      rate: rate ? formatDecimalPlaces(new BigNumber(rate).div(1000000), 4) : '0',
      value: rate ? `${formatDecimalPlaces(new BigNumber(rate).div(10000), 4)}%/1h` : '0.0000%/1h',
    };
  }, [fundingRates, tradeToken, tradeType]);

  const payToken: TApproveKey = useMemo(() => {
    if (tradeTokenType === TradeTokenType.U) {
      return 'USDT';
    }
    return TRADE_TOKENS[tradeToken] as TApproveKey;
  }, [tradeToken, tradeTokenType]);

  const decimalScale = useMemo(() => {
    return payToken === 'USDT' ? DECIMAL_SCALE[0] : DECIMAL_SCALE[1];
  }, [payToken]);

  const { isLoading: approveIsLoading, writeAsync: approveWrite } = useApprove(payToken);

  const isLoading = useMemo(() => {
    return approveIsLoading || increaseLimitOrderIsLoading || increaseMarketOrderIsLoading;
  }, [approveIsLoading, increaseLimitOrderIsLoading, increaseMarketOrderIsLoading]);

  const balance = useBalance({
    address,
    token: tradeTokenType === TradeTokenType.U ? USDT : tradeToken,
    watch: true,
  });

  const marketPrice = useMemo(() => {
    return prices?.[tradeToken] ?? '0.00';
  }, [prices, tradeToken]);

  const address_path = useMemo(() => {
    let _path: string[] = [];
    if (tradeType === TradeType.Long && tradeTokenType === TradeTokenType.Token) {
      _path = [tradeToken];
    } else if (tradeType === TradeType.Short && tradeTokenType === TradeTokenType.U) {
      _path = [USDT];
    } else if (tradeType === TradeType.Long && tradeTokenType === TradeTokenType.U) {
      _path = [USDT, tradeToken];
    } else if (tradeType === TradeType.Short && tradeTokenType === TradeTokenType.Token) {
      _path = [tradeToken, USDT];
    }
    return _path;
  }, [tradeToken, tradeTokenType, tradeType]);

  const minOut = useMemo(() => {
    if (prices && address_path.length > 1) {
      const path_0_price = prices[address_path[0] as TradeTokenKey];
      const path_1_price = prices[address_path[1] as TradeTokenKey];
      if (
        !payAmount ||
        Number(payAmount) <= 0 ||
        !path_0_price ||
        Number(path_0_price) <= 0 ||
        !path_1_price ||
        Number(path_1_price) <= 0
      ) {
        return 0;
      }
      const slippage = getStorage('slippage') ?? SLIPPAGES[0];
      const result = new BigNumber(payAmount)
        .times(new BigNumber(path_0_price).div(path_1_price))
        .times(10 ** TOKENS_DECIMALS[ALL_TOKENS[address_path[0] as TradeTokenKey] as TApproveKey])
        .div(10 ** TOKENS_DECIMALS[ALL_TOKENS[address_path[1] as TradeTokenKey] as TApproveKey])
        .times(new BigNumber(1).minus(slippage));
      return result.toNumber();
    }
    return 0;
  }, [address_path, payAmount, prices]);

  const openPrice = useMemo(() => {
    if (orderType === OrderType.Market) {
      return prices?.[tradeToken] ?? '0.00';
    }
    return limitPrice.toString();
  }, [orderType, limitPrice, prices, tradeToken]);

  const isSmallCollateralDelta = useMemo(() => {
    if (Number(payAmount) <= 0) return false;
    const delta =
      tradeTokenType === TradeTokenType.U
        ? payAmount
        : new BigNumber(payAmount).times(openPrice).toString();
    return Number(delta) < 10;
  }, [openPrice, payAmount, tradeTokenType]);

  const collateralDelta = useMemo(() => {
    if (Number(payAmount) <= 0) return '0';

    const delta =
      tradeTokenType === TradeTokenType.U
        ? payAmount
        : new BigNumber(payAmount).times(openPrice).toString();

    return delta;
  }, [openPrice, payAmount, tradeTokenType]);

  const { feeBasisPoints, swapFee } = useFeeBasisPoints(address_path, collateralDelta);

  const sizeDelta = useMemo(() => {
    if (Number(payAmount) <= 0) return 0;

    const leverageRate = new BigNumber(leverageMultiple).times(0.00095235);
    const sizeData = new BigNumber(payAmount)
      .times(new BigNumber(1).minus(feeBasisPoints))
      .times(new BigNumber(1).minus(leverageRate))
      .times(leverageMultiple);
    if (tradeTokenType === TradeTokenType.U) {
      return parsePrice(sizeData.toString());
    }

    return parsePrice(sizeData.times(openPrice).toString());
  }, [feeBasisPoints, leverageMultiple, openPrice, payAmount, tradeTokenType]);

  const execFee = useMemo(() => {
    const orderFeeAmount = orderType === OrderType.Limit ? limitOrderFee : marketOrderFee;
    const ethPrice = prices?.[APPROVE_TOKENS['ETH' as TApproveKey] as TradeTokenKey];
    const gasPrice = feeData?.gasPrice;
    if (ethPrice && orderFeeAmount && gasPrice) {
      const maxFee = Number(orderFeeAmount) > Number(gasPrice) ? orderFeeAmount : gasPrice;
      const ethFee = new BigNumber(ethPrice).times(Number(maxFee)).times(2150000);
      const result = formatDecimalPlaces(fromBigNumberAmount(ethFee, 'ETH'));
      return result;
    }
    return '0';
  }, [feeData?.gasPrice, limitOrderFee, marketOrderFee, orderType, prices]);

  const openFee = useMemo(() => {
    if (Number(payAmount) > 0 && openPrice && balance.data?.formatted) {
      const limit =
        Number(payAmount) > Number(balance.data.formatted) ? balance.data.formatted : payAmount;
      if (tradeTokenType === TradeTokenType.U) {
        return formatDecimalPlaces(
          new BigNumber(limit).times(leverageMultiple).times(0.001),
          DECIMAL_SCALE[1],
        );
      }

      return formatDecimalPlaces(
        new BigNumber(limit).times(openPrice).times(leverageMultiple).times(0.001),
      );
    }

    return '0';
  }, [balance.data?.formatted, leverageMultiple, openPrice, payAmount, tradeTokenType]);

  const fundingFee = useMemo(() => {
    if (Number(payAmount) > 0 && balance.data?.formatted) {
      const limit =
        Number(payAmount) > Number(balance.data.formatted) ? balance.data.formatted : payAmount;
      const rate = currentFundingRate.rate;
      if (tradeTokenType === TradeTokenType.U) {
        return formatDecimalPlaces(new BigNumber(limit).times(rate));
      }
      return formatDecimalPlaces(new BigNumber(limit).times(rate).times(openPrice));
    }

    return '0';
  }, [balance.data?.formatted, currentFundingRate.rate, openPrice, payAmount, tradeTokenType]);

  const totalFee = useMemo(() => {
    return formatDecimalPlaces(new BigNumber(execFee).plus(openFee).plus(fundingFee).plus(swapFee));
  }, [execFee, fundingFee, openFee, swapFee]);

  const params = useMemo(() => {
    const isProfit = checkedStopLimit && Number(stopProfitLossValue.takeProfit.value) > 0;
    const isLoss = checkedStopLimit && Number(stopProfitLossValue.stopLoss.value) > 0;
    let profitCollateralDelta = parsePrice('0');
    let lossCollateralDelta = parsePrice('0');
    if (isProfit && Number(payAmount) > 0) {
      const profitCollateral = new BigNumber(stopProfitLossValue.takeProfit.value);
      if (tradeTokenType === TradeTokenType.U) {
        profitCollateralDelta = parsePrice(profitCollateral.toString());
      } else {
        profitCollateralDelta = parsePrice(profitCollateral.times(leverageMultiple).toString());
      }
    }
    if (isLoss && Number(payAmount) > 0) {
      const lossCollateral = new BigNumber(stopProfitLossValue.stopLoss.value);
      if (tradeTokenType === TradeTokenType.U) {
        lossCollateralDelta = parsePrice(lossCollateral.toString());
      } else {
        lossCollateralDelta = parsePrice(lossCollateral.times(leverageMultiple).toString());
      }
    }
    const stopLimit = [
      isProfit ? parsePrice(stopProfitLossValue.takeProfit.value) : parsePrice('0'),
      sizeDelta,
      profitCollateralDelta,
      isLoss ? parsePrice(stopProfitLossValue.stopLoss.value) : parsePrice('0'),
      sizeDelta,
      lossCollateralDelta,
    ];
    if (orderType === OrderType.Limit) {
      return [parsePrice(openPrice.toString()), ...stopLimit];
    }

    const slippage = getStorage('slippage') ?? SLIPPAGES[0];
    const priceBasisPoints =
      tradeType === TradeType.Long
        ? new BigNumber(1).plus(slippage)
        : new BigNumber(1).minus(slippage);
    const priceLimit = parsePrice(new BigNumber(marketPrice).times(priceBasisPoints).toString());

    return [priceLimit, ...stopLimit];
  }, [
    checkedStopLimit,
    leverageMultiple,
    marketPrice,
    openPrice,
    orderType,
    payAmount,
    sizeDelta,
    stopProfitLossValue.stopLoss.value,
    stopProfitLossValue.takeProfit.value,
    tradeTokenType,
    tradeType,
  ]);

  const positionAmount = useMemo(() => {
    return formatDecimalPlaces(
      fromBigNumberPrice(new BigNumber(sizeDelta.toString())).div(openPrice),
      DECIMAL_SCALE[1],
    );
  }, [openPrice, sizeDelta]);

  const currentAllowance = useMemo(() => {
    return allowance?.[payToken] ?? 0;
  }, [allowance, payToken]);

  const needApprove = useMemo(() => {
    return payAmount && fromBigNumberAmount(currentAllowance, payToken).lt(payAmount);
  }, [currentAllowance, payAmount, payToken]);

  const isInsufficientBalance = useMemo(() => {
    return !!(
      payAmount &&
      balance.data?.formatted &&
      Number(payAmount) > Number(balance.data.formatted)
    );
  }, [balance.data?.formatted, payAmount]);

  const isInsufficientLiquidity = useMemo(() => {
    if (!poolAmounts || !reservedAmounts) return false;
    const tokenAddress = tradeTokenType === TradeTokenType.U ? USDT : tradeToken;
    const liquidity = new BigNumber(poolAmounts[tokenAddress]).minus(reservedAmounts[tokenAddress]);
    return Number(payAmount) > 0 && new BigNumber(payAmount).times(leverageMultiple).gt(liquidity);
  }, [leverageMultiple, payAmount, poolAmounts, reservedAmounts, tradeToken, tradeTokenType]);

  const isStopProfitLossError = useMemo(() => {
    if (Number(stopProfitLossValue.takeProfit.value) > 0) {
      if (
        tradeType === TradeType.Long &&
        Number(stopProfitLossValue.takeProfit.value) < Number(openPrice)
      ) {
        return true;
      } else if (
        tradeType === TradeType.Short &&
        Number(stopProfitLossValue.takeProfit.value) > Number(openPrice)
      ) {
        return true;
      }
    } else if (Number(stopProfitLossValue.stopLoss.value) > 0) {
      if (
        tradeType === TradeType.Long &&
        Number(stopProfitLossValue.stopLoss.value) > Number(openPrice)
      ) {
        return true;
      } else if (
        tradeType === TradeType.Short &&
        Number(stopProfitLossValue.stopLoss.value) < Number(openPrice)
      ) {
        return true;
      }
    }
    return false;
  }, [
    openPrice,
    stopProfitLossValue.stopLoss.value,
    stopProfitLossValue.takeProfit.value,
    tradeType,
  ]);

  const isDisabled = useMemo(() => {
    return (
      Number(payAmount) <= 0 ||
      isInsufficientBalance ||
      isSmallCollateralDelta ||
      isInsufficientLiquidity ||
      isStopProfitLossError
    );
  }, [
    isInsufficientBalance,
    isInsufficientLiquidity,
    isSmallCollateralDelta,
    isStopProfitLossError,
    payAmount,
  ]);

  useEffect(() => {
    setPayAmount('');
    setPercentage(0);
  }, [payToken]);

  useEffect(() => {
    setStopProfitLossValue(stopProfitLossDefaultValue);
    if (orderType === OrderType.Limit && Number(marketPrice) > 0) {
      setLimitPrice(marketPrice);
    }
  }, [tradeType, tradeToken, orderType]);

  useEffect(() => {
    if (orderType === OrderType.Limit && Number(marketPrice) > 0 && Number(limitPrice) <= 0) {
      setLimitPrice(marketPrice);
    }
  }, [prices, orderType]);

  useEffect(() => {
    if (Number(payAmount) > 0 && Number(sizeDelta) > 0 && Number(params[0]) > 0) {
      run(
        tradeType === TradeType.Long,
        sizeDelta.toString(),
        parsePrice(collateralDelta).toString(),
        params[0].toString(),
      );
    } else {
      cancel();
      mutate(0);
    }
  }, [payAmount, sizeDelta, collateralDelta, params[0], tradeType]);

  const handleSetStopProfitLossValue = (type: StopType) => {
    setStopProfitLossValue({ ...stopProfitLossValue, type, isOpen: true });
  };

  const handleStopProfitLossValue = (type: StopType, value: string, ratio: string) => {
    if (type === StopType.TakeProfit) {
      setStopProfitLossValue({
        ...stopProfitLossValue,
        takeProfit: { value, ratio },
        isOpen: false,
      });
    } else {
      setStopProfitLossValue({ ...stopProfitLossValue, stopLoss: { value, ratio }, isOpen: false });
    }
  };

  const onChangeSlider = (v: number | number[]) => {
    if (typeof v === 'number') {
      setPercentage(v);
      if (balance.data?.formatted && Number(balance.data.formatted) > 0) {
        const bn = new BigNumber(balance.data.formatted);
        const payResult = formatDecimalPlaces(bn.times(v), decimalScale);
        setPayAmount(payResult);
      }
    }
  };

  const handleSetOrderType = (key: React.Key) => {
    const type = key as OrderType;
    setOrderType(type);
    if (type === OrderType.Limit) {
      setLimitPrice(prices?.[tradeToken] ?? '0.00');
    }
  };

  const handleLimitPrice = (type: 'minus' | 'plus') => {
    const bn = new BigNumber(limitPrice);
    if (type === 'minus') {
      const value = bn.minus(0.01).toNumber();
      if (value >= 0) {
        setLimitPrice(bn.minus(0.01).toString());
      }
    } else {
      setLimitPrice(bn.plus(0.01).toString());
    }
  };

  const handleSetPayAmount = (value: string) => {
    setPayAmount(value);
    if (Number(value) <= 0) {
      setCheckedStopLimit(false);
      setStopProfitLossValue(stopProfitLossDefaultValue);
    }
    if (balance.data?.formatted) {
      const bn = new BigNumber(value);
      const rate = Number(formatDecimalPlaces(bn.div(balance.data.formatted), 1));
      if (rate >= 0) {
        setPercentage(rate);
      }
    }
  };

  const handleConfirm = async () => {
    setShowSlippageWarning(false);
    if (orderType === OrderType.Limit && increaseLimitOrderWrite) {
      const { hash } = await increaseLimitOrderWrite({
        args: [
          [address_path[0]], // _path
          parseAmount(payAmount, payToken), // _amountIn
          tradeToken, // _indexToken: BTC or ETH
          0, // _minOut
          sizeDelta, // _sizeDelta: payAmount * leverageMultiple
          address_path.at(-1), // _collateralToken
          tradeType === TradeType.Long, // _isLong
          tradeTokenType === TradeTokenType.Token, // _isTokenBased
          limitOrderFee, // _executionFee
          tradeType === TradeType.Short, // _triggerAboveThreshold
          params, // params
        ],
        value: limitOrderFee as unknown as bigint,
      }).catch((err) => {
        console.log(err);
        return { hash: '' };
      });
      setHash(hash);
      setShowFeedBack(true);
    } else if (orderType === OrderType.Market && increaseMarketOrderWrite) {
      const { hash } = await increaseMarketOrderWrite({
        args: [
          address_path, // _path
          tradeToken, // _indexToken: BTC or ETH
          parseAmount(payAmount, payToken), // _amountIn
          0, // _minOut
          sizeDelta, // _sizeDelta: payAmount * leverageMultiple
          tradeType === TradeType.Long, // _isLong
          params, // params
          marketOrderFee, // _executionFee
          zeroAddress, // _callbackTarget
        ],
        value: marketOrderFee as unknown as bigint,
      }).catch((err) => {
        console.log(err);
        return { hash: '' };
      });
      setHash(hash);
      setShowFeedBack(true);
    }
  };

  const handleIncreaseOrder = async () => {
    if (needApprove) {
      await approveWrite({
        args: [process.env.NEXT_PUBLIC_ROUTER_ADDRESS as Address, maxUint256],
      }).catch((err) => {
        console.log(err);
      });
    }
    const slippage = getStorage('slippage');
    if (slippage && Number(slippage) > MAX_SLIPPAGE) {
      setShowSlippageWarning(true);
    } else {
      handleConfirm();
    }
  };

  const handleSetSlippage = () => {
    setShowSlippageWarning(false);
    setTimeout(() => {
      setSettingSlippageIsOpen(true);
    }, 500);
  };

  return {
    address_path,
    currentFundingRate,
    isLoading,
    balance,
    payAmount,
    payToken,
    marketPrice,
    limitPrice,
    setLimitPrice,
    checkedStopLimit,
    setCheckedStopLimit,
    stopProfitLossValue,
    setStopProfitLossValue,
    isLeverOpen,
    setIsLeverOpen,
    showTips,
    setShowTips,
    showFeedBack,
    setShowFeedBack,
    showSlippageWarning,
    setShowSlippageWarning,
    hash,
    liquidationPrice,
    percentage,
    openPrice,
    swapFee,
    execFee,
    openFee,
    fundingFee,
    totalFee,
    positionAmount,
    isInsufficientBalance,
    isDisabled,
    decimalScale,
    isSmallCollateralDelta,
    isInsufficientLiquidity,
    onChangeSlider,
    handleSetStopProfitLossValue,
    handleStopProfitLossValue,
    handleSetOrderType,
    handleLimitPrice,
    handleSetPayAmount,
    handleIncreaseOrder,
    handleSetSlippage,
    handleConfirm,
  };
};
