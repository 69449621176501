import BigNumber from 'bignumber.js';
import { useMemo, useState } from 'react';
import { maxUint256 } from 'viem';
import { Address, useAccount, useContractRead, useContractReads, useContractWrite } from 'wagmi';

import { fromBigNumberAmount, parseAmount } from '@/lib/format';
import { getStorage } from '@/lib/storage';
import { useAllowance, useApprove } from '@/hooks/contract';

import { usePoolStore } from '@/stores';

import SLPStakingPoolABI from '@/abis/SLPStakingPool.json';
import SyncStakingRouterABI from '@/abis/SyncStakingRouter.json';
import { APPROVE_TOKENS } from '@/consts';

import { TApproveKey } from '@/types';

const useMintAndStakeSlpWrite = () => {
  const { isLoading: buyIsLoading, writeAsync: mintAndStakeSlpWrite } = useContractWrite({
    address: process.env.NEXT_PUBLIC_SYNCS_TAKING_ROUTER_ADDRESS as Address,
    // @ts-ignore
    abi: SyncStakingRouterABI,
    functionName: 'mintAndStakeSlp',
  });
  return {
    buyIsLoading,
    mintAndStakeSlpWrite,
  };
};
const useBatchClaim = () => {
  const { isLoading: batchClaimLoading, writeAsync: batchClaimWrite } = useContractWrite({
    address: process.env.NEXT_PUBLIC_SYNCS_TAKING_ROUTER_ADDRESS as Address,
    // @ts-ignore
    abi: SyncStakingRouterABI,
    functionName: 'batchClaim',
  });
  return {
    batchClaimLoading,
    batchClaimWrite,
  };
};

const useUnStakeAndRedeemSlpWrite = () => {
  const { isLoading: sellIsLoading, writeAsync: unStakeAndRedeemSlpWrite } = useContractWrite({
    address: process.env.NEXT_PUBLIC_SYNCS_TAKING_ROUTER_ADDRESS as Address,
    // @ts-ignore
    abi: SyncStakingRouterABI,
    functionName: 'unstakeAndRedeemSlp',
  });
  return {
    sellIsLoading,
    unStakeAndRedeemSlpWrite,
  };
};

const useUnstakeFromTokenAndRedeemSlp = () => {
  const {
    isLoading: unstakeFromTokenAndRedeemSlpLoading,
    writeAsync: unstakeFromTokenAndRedeemSlpLoadingWrite,
  } = useContractWrite({
    address: process.env.NEXT_PUBLIC_SYNCS_TAKING_ROUTER_ADDRESS as Address,
    // @ts-ignore
    abi: SyncStakingRouterABI,
    functionName: 'unstakeFromTokenAndRedeemSlp',
  });
  return {
    unstakeFromTokenAndRedeemSlpLoading,
    unstakeFromTokenAndRedeemSlpLoadingWrite,
  };
};

export const useMlpStaking = (token: TApproveKey) => {
  const { mintAndStakeSlpWrite, buyIsLoading } = useMintAndStakeSlpWrite();
  const { unStakeAndRedeemSlpWrite, sellIsLoading } = useUnStakeAndRedeemSlpWrite();
  const { unstakeFromTokenAndRedeemSlpLoading, unstakeFromTokenAndRedeemSlpLoadingWrite } =
    useUnstakeFromTokenAndRedeemSlp();
  const { batchClaimWrite, batchClaimLoading } = useBatchClaim();
  const [mlpValue, setMlpValue] = useState('');
  const [swapValue, setSwapValue] = useState('');
  const { tab, poolToken } = usePoolStore();
  const { writeAsync: approveWrite, isLoading: approveIsLoading } = useApprove(token);
  const { allowance } = useAllowance(process.env.NEXT_PUBLIC_MLP_MANAGER_ADDRESS as Address);
  const { address } = useAccount();
  const { data }: { data: any } = useContractRead({
    address: process.env.NEXT_PUBLIC_MLP_STAKING_ADDRESS as Address,
    // @ts-ignore
    abi: SLPStakingPoolABI,
    enabled: !!address,
    functionName: 'userStaked',
    args: [address],
    watch: true,
  });

  const { data: tokenData }: { data: any } = useContractReads({
    enabled: !!address,
    watch: true,
    contracts: [
      {
        address: process.env.NEXT_PUBLIC_MLP_STAKING_ADDRESS as Address,
        // @ts-ignore
        abi: SLPStakingPoolABI,
        functionName: 'maxTokenStakeAmount',
        args: [APPROVE_TOKENS[token] as Address],
      },
      {
        address: process.env.NEXT_PUBLIC_MLP_STAKING_ADDRESS as Address,
        // @ts-ignore
        abi: SLPStakingPoolABI,
        functionName: 'tokenStakeAmount',
        args: [APPROVE_TOKENS[token] as Address],
      },
    ],
  });
  const currentAllowance = useMemo(() => {
    return allowance?.[token as TApproveKey] ?? 0;
  }, [allowance, token]);

  const needApprove = useMemo(() => {
    return (
      tab === 1 &&
      swapValue &&
      fromBigNumberAmount(currentAllowance, token as TApproveKey).lt(swapValue)
    );
  }, [currentAllowance, swapValue, tab, token]);

  const HandleBuySlp = async (tokenAddress: Address, payAmount: string, amount = '0') => {
    if (needApprove) {
      await approveWrite({
        args: [process.env.NEXT_PUBLIC_MLP_MANAGER_ADDRESS as Address, maxUint256],
      }).catch((err) => {
        console.log(err);
      });
    }
    const { hash } = await mintAndStakeSlpWrite({
      args: [tokenAddress, parseAmount(payAmount, poolToken), 0, parseAmount(amount, 'MLP')],
    }).catch((err) => {
      console.log(err);
      return { hash: '' };
    });
    return hash;
  };
  const handleSellSlp = async (tokenAddress: Address, amount: string, rewardProof: any) => {
    if (needApprove) {
      approveWrite({
        args: [process.env.NEXT_PUBLIC_MLP_MANAGER_ADDRESS as Address, maxUint256],
      }).catch((err) => {
        console.log(err);
      });
    }
    const isAction = Number(rewardProof?.amount) ? true : false;
    const { hash } = await unStakeAndRedeemSlpWrite({
      args: [
        parseAmount(amount, 'MLP'),
        tokenAddress,
        0,
        address,
        [isAction, rewardProof?.amount || 0, rewardProof?.proof || []],
      ],
    }).catch((err) => {
      console.log(err);
      return { hash: '' };
    });
    return hash;
  };

  const handleUnstakeFromTokenAndRedeemSlp = async (
    tokenAddress: Address,
    slpAmount: string,
    rewardProof: any,
    claimData: any,
    poolToken: string,
  ) => {
    if (needApprove) {
      await approveWrite({
        args: [process.env.NEXT_PUBLIC_MLP_MANAGER_ADDRESS as Address, maxUint256],
      }).catch((err) => {
        console.log(err);
      });
    }
    const isAction = new BigNumber(claimData && claimData[1]).toNumber() ? true : false;
    const { hash } = await unstakeFromTokenAndRedeemSlpLoadingWrite({
      args: [
        getStorage('tokenAddress'),
        tokenAddress,
        0,
        address,
        [
          isAction,
          claimData && claimData[1],
          rewardProof?.[poolToken]?.extraAmount || 0,
          rewardProof?.[poolToken]?.proof || [],
        ],
      ],
    }).catch((err) => {
      console.log(err);
      return { hash: '' };
    });

    return hash;
  };

  const isLoading = useMemo(() => {
    return buyIsLoading || sellIsLoading || approveIsLoading;
  }, [buyIsLoading, sellIsLoading, approveIsLoading]);

  return {
    dataNum: fromBigNumberAmount(data, 'MLP').toString(),
    HandleBuySlp,
    handleSellSlp,
    isLoading,
    swapValue,
    setMlpValue,
    mlpValue,
    setSwapValue,
    needApprove,
    batchClaimWrite,
    batchClaimLoading,
    tokenData,
    unstakeFromTokenAndRedeemSlpLoading,
    handleUnstakeFromTokenAndRedeemSlp,
  };
};
