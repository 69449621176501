import { useTranslation } from 'next-i18next';

export const useKLline = () => {
  const { t } = useTranslation(['kline']);
  const ZH_CHART_TIME = {
    time: '',
    open: t('opening'),
    high: t('highest'),
    low: t('lowest'),
    close: t('closing'),
    volume: t('volume'),
  };
  return {
    ZH_CHART_TIME,
  };
};
