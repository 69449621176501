import BigNumber from 'bignumber.js';
import { Address, useAccount, useContractRead } from 'wagmi';

import { formatDecimalPlaces, fromBigNumberAmount } from '@/lib/format';

import { usePoolStore } from '@/stores';

import SyncStakingRouterABI from '@/abis/SyncStakingRouter.json';
import { APPROVE_TOKENS } from '@/consts';

export const useLastTokenAmount = () => {
  const { poolToken } = usePoolStore();
  const { address } = useAccount();
  const { data } = useContractRead({
    address: process.env.NEXT_PUBLIC_SYNCS_TAKING_ROUTER_ADDRESS as Address,
    // @ts-ignore
    abi: SyncStakingRouterABI,
    enabled: !!address,
    functionName: 'lastTokenAmountOut',
    args: [address, APPROVE_TOKENS[poolToken] as Address],
    watch: true,
  });
  return {
    lastTokenAmount: formatDecimalPlaces(
      fromBigNumberAmount(new BigNumber(data as any), poolToken),
      4,
    ),
  };
};
