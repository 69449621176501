import { Address } from 'viem';
import { erc20ABI, useAccount, useContractReads } from 'wagmi';

import { useAllowanceStore } from '@/stores';

import { APPROVE_TOKENS } from '@/consts';

import { IAllowance, TApproveKey } from '@/types';

export const useAllowance = (contractAddress: Address) => {
  const { address } = useAccount();
  const { setAllowance } = useAllowanceStore();

  const contracts = Object.values(APPROVE_TOKENS).map((item) => ({
    address: item as Address,
    abi: erc20ABI,
    functionName: 'allowance',
    args: [address as Address, contractAddress],
  }));

  const { data, isSuccess } = useContractReads({
    enabled: !!address,
    watch: true,
    contracts,
    onSuccess(data) {
      const allowance = {} as IAllowance;

      if (data) {
        Object.keys(APPROVE_TOKENS).forEach((item, index) => {
          if (data[index]?.result) {
            allowance[item as TApproveKey] = data[index].result as number;
          }
        });
      }

      setAllowance(Object.keys(allowance).length ? allowance : undefined);
    },
  });

  const allowance = {} as IAllowance;

  if (isSuccess && data) {
    Object.keys(APPROVE_TOKENS).forEach((item, index) => {
      if (data[index]?.result) {
        allowance[item as TApproveKey] = data[index].result as number;
      }
    });
  }

  return {
    allowance: Object.keys(allowance).length ? allowance : undefined,
  };
};
