import { zeroAddress } from 'viem';
import { Address, useAccount, useContractReads } from 'wagmi';

import { parseDecreaseOrdersDataV2, parseIncreaseOrdersDataV2 } from '@/lib/legacy';
import { useEntrustOrder } from '@/hooks/api';

import OrderBookReaderABI from '@/abis/OrderBookReader.json';

export const useGetOrders = () => {
  const { address } = useAccount();
  const { orderList } = useEntrustOrder();

  const ret: any = {};
  const retMap: any = {};
  if (orderList.data) {
    for (const key of Object.keys(orderList.data)) {
      const indexMapTime: any = {};
      ret[key] = orderList.data[key]
        .map((val: any) => {
          indexMapTime[val.value] = val;
          return parseInt(val.value);
        })
        .sort((a: any, b: any) => a - b);
      retMap[key] = indexMapTime;
    }
  }

  const increaseIndexes = ret.Increase || [];
  const decreaseIndexes = ret.Decrease || [];

  const { data, isLoading, isSuccess } = useContractReads({
    enabled: !!address,
    watch: true,
    contracts: [
      // {
      //   address: process.env.NEXT_PUBLIC_ORDER_BOOK_READER_ADDRESS as Address,
      //   // @ts-ignore
      //   abi: OrderBookReaderABI,
      //   functionName: 'getSwapOrders',
      //   args: [
      //     process.env.NEXT_PUBLIC_ORDER_BOOK_ADDRESS as Address,
      //     address as Address,
      //     swapIndexes,
      //   ],
      // },
      {
        address: process.env.NEXT_PUBLIC_ORDER_BOOK_READER_ADDRESS as Address,
        // @ts-ignore
        abi: OrderBookReaderABI,
        functionName: 'getIncreaseOrdersV2',
        args: [
          process.env.NEXT_PUBLIC_ORDER_BOOK_ADDRESS as Address,
          address as Address,
          increaseIndexes,
        ],
      },
      {
        address: process.env.NEXT_PUBLIC_ORDER_BOOK_READER_ADDRESS as Address,
        // @ts-ignore
        abi: OrderBookReaderABI,
        functionName: 'getDecreaseOrdersV2',
        args: [
          process.env.NEXT_PUBLIC_ORDER_BOOK_ADDRESS as Address,
          address as Address,
          decreaseIndexes,
        ],
      },
    ],
  });

  let list: any[] = [];
  if (isSuccess) {
    // @ts-ignore
    // const swapOrders = parseSwapOrdersData(data[0]?.result, address, swapIndexes);

    // @ts-ignore
    const increaseOrders = parseIncreaseOrdersDataV2(data[0]?.result, increaseIndexes);

    // @ts-ignore
    const decreaseOrders = parseDecreaseOrdersDataV2(data[1]?.result, decreaseIndexes);

    list = [...increaseOrders, ...decreaseOrders].filter((item) => item.indexToken !== zeroAddress);
  }

  list = list
    .map((item) => {
      if (retMap[item.type][item.index]) {
        return {
          ...item,
          commissionsNumber: retMap[item.type][item.index].amount,
          timestamp: retMap[item.type][item.index].timestamp,
          leverage: retMap[item.type][item.index].leverage,
        };
      }
      return item;
    })
    .sort((a, b) => b.timestamp - a.timestamp);

  return {
    data: list,
    isLoading,
    isSuccess,
  };
};
