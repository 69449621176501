import { Address, erc20ABI, useContractWrite } from 'wagmi';

import { APPROVE_TOKENS } from '@/consts';

import { TApproveKey } from '@/types';

export const useApprove = (symbol: TApproveKey) => {
  const { isLoading, writeAsync } = useContractWrite({
    address: APPROVE_TOKENS[symbol] as Address,
    abi: erc20ABI,
    functionName: 'approve',
  });

  return {
    isLoading,
    writeAsync,
  };
};
