import { Address, useAccount, useContractRead } from 'wagmi';

import TokenRewardDistributorABI from '@/abis/TokenRewardDistributor.json';

export const useClaim = (amount: string, extraAmount: string, roof: any, poolToken: string) => {
  const { address } = useAccount();
  const token = () => {
    const data = {
      USDT: process.env.NEXT_PUBLIC_TOKEN_REWARD_DISTRIBUTOR_USDT as Address,
      ETH: process.env.NEXT_PUBLIC_TOKEN_REWARD_DISTRIBUTOR_ETH as Address,
      BTC: process.env.NEXT_PUBLIC_TOKEN_REWARD_DISTRIBUTOR_BTC as Address,
    };
    return data[poolToken as keyof typeof data];
  };

  const { data }: { data: any } = useContractRead({
    address: token() as Address,
    abi: TokenRewardDistributorABI,
    enabled: !!address,
    functionName: 'canClaimForAccount',
    args: [address, amount, extraAmount, roof],
  });
  return {
    data,
  };
};
